import { Menu } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import { useNotificationsQuery } from '@vangst/services/oogst/types'
import useLogout from '@vangst/services/oogst/viewer/useLogout'
import useViewer, { ViewerType } from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { useRouter } from 'next/router'
import { Fragment, memo, useMemo, useState } from 'react'
import { MdNotifications, MdSearch } from 'react-icons/md'
import VangstMark from '../../components/assets/marks/VangstMark'
import VangstType from '../../components/assets/marks/VangstType'
import ButtonPopover from '../../components/feedback/ButtonPopover'
import ClickyLiner from '../../components/navigation/ClickyLiner'
import ClickyLink from '../../components/navigation/ClickyLink'
import {
  MenuItemClicky,
  MenuItemClickyKid,
  MenuItems,
} from '../../components/navigation/Menus'
import ListPseudos from '../../components/views/ListPseudos'
import ActivitiesRenderer from '../application/ActivitiesRenderer'
import DialogContact from '../job/feedback/DialogContact'
import NavbarMenuDialogMain from './navigation/NavbarMenuDialogMain'
import NavbarMenuDialogSearch from './navigation/NavbarMenuDialogSearch'
import NavbarMenuProfile from './navigation/NavbarMenuProfile'

// -------------------------------------

function getRoutesProfile(viewer?: ViewerType) {
  // Logged out...
  if (viewer == null) {
    return [
      { href: Routes.SIGN_UP, label: 'Create an Account' },
      { href: Routes.SIGN_IN, label: 'Sign in' },
      { href: Routes.ABOUT_CONTACT, label: 'Contact a Human' },
    ]
  }

  const routes = viewer.routes
  const member = [
    { href: Routes.DASHBOARD, label: 'My Dashboard' },
    { href: routes?.detail, label: 'View My Profile' },
    { href: routes?.edit, label: 'Edit My Profile' },
    { href: routes?.jobApplications, label: 'My Applications' },
    { href: routes?.connections, label: 'My Connections' },
    { href: routes?.messages, label: 'Messages' },
  ]

  // Member...
  return member
}

function getRoutesProfileClient(viewer?: ViewerType) {
  // Company...
  if (viewer?.client != null) {
    const routes = viewer.client.routes
    return [
      { href: routes?.jobPostings, label: 'Job Postings' },
      { href: routes?.jobsNew, label: 'Post a Job' },
      { href: routes?.detail, label: 'View Company Profile' },
      { href: routes?.edit, label: 'Edit Company Profile' },
      { href: routes?.followers, label: 'Company Followers' },
    ]
  }
  return null
}

function getRoutesSearch() {
  return [
    { href: Routes.JOBS, label: 'Jobs' },
    { href: Routes.REPORTS_STATE_REQUIREMENTS, label: 'State Requirements' },
    { href: Routes.COMPANIES, label: 'Companies' },
    { href: Routes.MEMBERS, label: 'Members' },
    { href: Routes.TRAININGS, label: 'Trainings' },
  ]
}

function getRoutesPrimary() {
  return [
    // { href: Routes.GIGS, label: 'GIGS' },
    // { href: Routes.DIRECT_HIRE, label: 'Direct Hire' },
    // { href: Routes.PLATFORM, label: 'Platform' },
    // { href: Routes.LEARN, label: 'Learn' },
    // { href: Routes.RESOURCES, label: 'Resources' },
  ]
}

// -------------------------------------

function Navbar() {
  const { asPath } = useRouter()
  const { authenticated, authenticating, viewer } = useViewer()
  const [isDialogMainActive, setIsDialogMainActive] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false)
  const toggleDialogMain = () => setIsDialogMainActive(!isDialogMainActive)
  const hideDialog = () => setIsDialogMainActive(false)

  const routesProfile = useMemo(() => getRoutesProfile(viewer), [viewer])
  const routesProfileClient = useMemo(
    () => getRoutesProfileClient(viewer),
    [viewer],
  )
  const routesPrimary = useMemo(() => getRoutesPrimary(), [])
  const routesSearch = useMemo(() => getRoutesSearch(), [])

  const logout = useLogout()
  const signOut = () => {
    hideDialog()
    logout.mutate({})
  }

  const { data } = useNotificationsQuery()
  const hasAlerts =
    data?.getNotifications?.find((obj) => obj.type === 'Activity')?.count || 0

  const notificationCounts = {
    chatMessages:
      data?.getNotifications?.find((obj) => obj.type === 'ChatMessage')
        ?.count || 0,
    connections:
      data?.getNotifications?.find((obj) => obj.type === 'Connection')?.count ||
      0,
  }

  const hasAnyNotification = Object.keys(notificationCounts).some(
    (key: any) =>
      notificationCounts[key as keyof typeof notificationCounts] > 0,
  )

  return (
    <nav
      id="nav"
      aria-label="Primary Navigation"
      className="mood-white sticky top-0 z-30 h-12 w-full border-y sm:h-16"
      data-loading={authenticating === true ? authenticating : undefined}
    >
      <div className="contain-xc flex h-full items-center justify-between !pr-0">
        <div className="mr-4 flex h-full">
          <ClickyLink
            id="navbar-logo"
            className="flex items-center"
            title="vangst.com"
            href={Routes.JOBS}
          >
            <span className="flow-x-xs items-center">
              <VangstMark width="33" height="32" className="text-orange" />
              <VangstType width="98" height="14" />
            </span>
          </ClickyLink>
        </div>

        {/* The horizontal list of links */}
        <LinkBarPseudos
          className={clsx(
            'ml-auto text-grey',
            authenticating ? 'opacity-0' : 'opacity-100',
          )}
        >
          <Menu as="li" className="relative">
            <Menu.Button
              aria-current={/^\/(jobs|\/browse\/gigs|companies|members|trainings)(\?|$)/gi.test(
                asPath,
              )}
              as={Fragment}
            >
              <ClickyLiner
                as="icon-text"
                className="aria-[current=true]:text-orange hocus:text-orange"
                icon={MdSearch}
              >
                Search...
              </ClickyLiner>
            </Menu.Button>
            <Menu.Items as={MenuItems}>
              {routesSearch.map(({ href, label }) => (
                <MenuItemClicky activate key={href} href={href}>
                  <MenuItemClickyKid label={label} />
                </MenuItemClicky>
              ))}
            </Menu.Items>
          </Menu>
          {routesPrimary.map(({ href, label }) => (
            <li key={'service' + href}>
              <ClickyLiner
                activate
                className="aria-[current=true]:text-orange hocus:text-orange"
                href={href}
              >
                {label}
              </ClickyLiner>
            </li>
          ))}
        </LinkBarPseudos>

        {!authenticated && (
          <LinkBarPseudos
            className={clsx(
              'ml-8',
              authenticating ? 'opacity-0' : 'opacity-100',
            )}
          >
            <li>
              <ClickyLiner
                activate
                className="aria-[current=true]:text-orange hocus:text-orange"
                href={Routes.SIGN_IN}
              >
                Sign In
              </ClickyLiner>
            </li>
            <li>
              <ClickyLiner
                activate
                className="aria-[current=true]:text-orange hocus:text-orange"
                href={Routes.SIGN_UP}
              >
                Sign Up
              </ClickyLiner>
            </li>
          </LinkBarPseudos>
        )}

        {/* The Icon links for menus and dialogs */}
        <ul className="flex h-full text-orange">
          <li
            className={clsx(
              'relative',
              authenticated && viewer?.permissions?.internalUser
                ? 'flex'
                : 'hidden',
            )}
          >
            <ButtonPopover
              className={clsx(
                'h-full w-12 border-x border-grey-light hocus:bg-green sm:w-16',
                'aria-[expanded=true]:mood-green aria-[expanded=true]:text-white',
              )}
              classNamePanel="mood-grey-lightest absolute sm:right-0 -right-24 sm:top-12 top-8 z-40 mt-4 max-h-128 sm:w-144 w-80 overflow-y-auto p-4 pr-8 shadow-md"
              label="Alerts"
              icon={MdNotifications}
            >
              <ActivitiesRenderer
                variables={{ username: viewer?.username ?? '', first: 24 }}
                endpoint="getMemberAlerts"
              />
            </ButtonPopover>
            {hasAlerts > 0 && (
              <span className="absolute left-8 top-3 block h-2 w-2 rounded-full bg-blue sm:left-9 sm:top-4" />
            )}
          </li>
          <li
            className={clsx(
              'relative hidden',
              !authenticating && authenticated && 'lg:flex',
            )}
          >
            <NavbarMenuProfile
              hasBilling={viewer?.permissions.imposter}
              notificationCounts={notificationCounts}
              routesProfile={routesProfile}
              routesProfileClient={routesProfileClient}
              signOut={signOut}
              username={viewer?.username}
            />
            {hasAnyNotification && (
              <span className="absolute left-9 top-4 block h-2 w-2 rounded-full bg-blue" />
            )}
          </li>
          <li className="border-l border-grey-light lg:hidden">
            <NavbarMenuDialogSearch routes={routesSearch} />
          </li>
          <li className={clsx(!authenticating && 'lg:sr-only')}>
            <NavbarMenuDialogMain
              authenticated={authenticated}
              authenticating={authenticating}
              hasBilling={viewer?.permissions.imposter}
              hide={hideDialog}
              isExpanded={isDialogMainActive}
              routesProfile={routesProfile}
              routesProfileClient={routesProfileClient}
              routesPrimary={routesPrimary}
              signOut={signOut}
              toggle={toggleDialogMain}
            />
          </li>
        </ul>
      </div>
      {showContactForm && (
        <DialogContact
          email={viewer?.email ?? ''}
          phone={viewer?.phoneNumber ?? ''}
          firstName={viewer?.firstName ?? ''}
          lastName={viewer?.lastName ?? ''}
          state={viewer?.location?.state ?? ''}
          handleCancel={() => setShowContactForm(false)}
          hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_DEMO_SUPPORT}
          title="Book A Demo"
        />
      )}
    </nav>
  )
}

export default memo(Navbar)

// -------------------------------------

const LinkBarPseudos = (props: React.HTMLAttributes<HTMLUListElement>) => (
  <ListPseudos
    as="|"
    at="before"
    className={clsx(
      'hidden h-full transition',
      'lg:flex lg:pr-[0.5em]',
      '72em:gap-[1em] 72em:pr-[1em] [&_>_li]:72em:gap-[1em]',
      props.className,
    )}
  >
    {props.children}
  </ListPseudos>
)
