import useCompany, {
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import { memo } from 'react'
import { MdPeople } from 'react-icons/md'
import GroupHeader from '../../components/views/GroupHeader'
import Markdown from '../../components/views/Markdown'

type PropsType = React.HTMLAttributes<HTMLUListElement> & {
  readonly at: 'content' | 'disclosure'
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
}

function CompanyValuesRenderer(props: PropsType) {
  const { at, variables, options } = props
  const { data } = useCompany(variables, options)
  const company = data?.getClient

  const values = company?.companyValues || []
  if (!values.length) {
    return null
  }

  if (at === 'disclosure') {
    return (
      <section>
        <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
          Mission & Values
        </h2>
        <div className="mood-grey-lightest grid grid-cols-1 gap-4 break-words p-4">
          {values.map((value, i) => (
            <div key={i}>
              <div className="text-lg">{value.title}</div>
              <Markdown className="text-sm" renderType="job">
                {value.description}
              </Markdown>
            </div>
          ))}
        </div>
      </section>
    )
  }
  return (
    <section id="values" className="mt-20">
      <GroupHeader
        className="items-center justify-between"
        icon={MdPeople}
        title="Mission & Values"
      ></GroupHeader>
      <div className="mt-8 grid grid-cols-1 gap-2 break-words sm:grid-cols-2">
        {values.map((value, i) => (
          <div key={i} className="m-4 ml-0">
            <div className="mb-2 text-lg">{value.title}</div>
            <Markdown>{value.description}</Markdown>
          </div>
        ))}
      </div>
    </section>
  )
}

export default memo(CompanyValuesRenderer)
