import clsx from '@vangst/lib/clsx'
import useCompany, {
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import { memo } from 'react'
import { MdLocationPin } from 'react-icons/md'
import ClickyLink from '../../components/navigation/ClickyLink'
import GroupHeader from '../../components/views/GroupHeader'

type PropsType = React.HTMLAttributes<HTMLUListElement> & {
  readonly at: 'content' | 'disclosure'
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
}

function CompanyLocationsRenderer(props: PropsType) {
  const { at, variables, options } = props
  const { data } = useCompany(variables, options)
  const company = data?.getClient

  const locations = company?.companyLocations || []
  if (!locations.length) {
    return null
  }

  if (at === 'disclosure') {
    return (
      <section>
        <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
          Additional Locations
        </h2>
        <div className="mood-grey-lightest grid grid-cols-1 gap-2 break-words p-4 sm:flex">
          {locations.map((location, i) => (
            <div
              key={i}
              className={clsx(
                'my-4 flex w-40 justify-center pr-4',
                locations.length > 1 && 'border-r border-grey-light',
              )}
            >
              <div>
                <div className="flex items-center gap-x-2">
                  <MdLocationPin />
                  <div className="my-3 text-sm">
                    {location.city}, {location.state}
                  </div>
                </div>
                <ClickyLink
                  className="link-orange grid"
                  href={`http://maps.google.com/?q=${location.address}, ${location.city}, ${location.state} ${location.postalCode}`}
                >
                  <span className="text-sm">{location.address}</span>
                  <span className="text-sm">
                    {location.city}, {location.state} {location.postalCode}
                  </span>
                </ClickyLink>
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }

  return (
    <section id="locations" className="mt-20">
      <GroupHeader
        className="items-center justify-between"
        icon={MdLocationPin}
        title="Additional Locations"
      ></GroupHeader>
      <div className="mt-10 grid grid-cols-2 gap-2 sm:-ml-10 sm:grid-cols-4">
        {locations.map((location, i) => (
          <div
            key={i}
            className={clsx(
              'my-4 flex justify-center border-r border-grey-light pr-2',
            )}
          >
            <div className="text-sm">
              <MdLocationPin className="ml-2" />
              <div className="my-3">
                {location.city}, {location.state}
              </div>
              <div>{location.address}</div>
              <div>
                {location.city}, {location.state} {location.postalCode}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default memo(CompanyLocationsRenderer)
