import clsx from '@vangst/lib/clsx'
import { memo } from 'react'
import { IconType } from 'react-icons'
import { MdOutlineInfo } from 'react-icons/md'
import Disclosure from '../navigation/Disclosure'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly icon?: IconType
  readonly children: React.ReactNode
}

function Tooltip(props: PropsType) {
  const { className = 'mood-grey-light', children, icon } = props
  const Icon = icon ?? MdOutlineInfo

  const Summary = () => (
    <summary
      className={clsx(
        'ml-auto flex h-6 w-6 cursor-pointer list-none items-center justify-center transition',
        'rounded-full group-open/tooltip:rounded-b-none',
        className,
      )}
    >
      <Icon className="mb-1 h-4 w-4 self-end" />
    </summary>
  )

  return (
    <Disclosure className="group/tooltip" summary={Summary}>
      <div className={clsx('px-4 py-2 text-sm', className)} role="tooltip">
        {children}
      </div>
    </Disclosure>
  )
}

/**
 * A variation of the @see [Disclosure](https://github.com/vangst/oogmerk/blob/main/apps/web/components/navigation/Disclosure.tsx) element that uses an icon as a summary.
 * Defaults to a light grey and a question mark icon, but can be styled using the `mood` prop - must match an existing [mood](https://github.com/vangst/oogmerk/blob/main/packages/tailwind/plugins/colors.js) style.
 *
 * @example
 * <Tooltip icon={MdOutlineInfo}>
 *   <p>
 *     Tool tip dolor sit amet consectetur. Ac elementum sed venenatis
 *     sagittis sed etiam. Facilising curabitur fusce molestie porttitor
 *     urna. Et non vitae vel risus scelerisque duis.
 *    </p>
 * </Tooltip>
 */
export default memo(Tooltip)
