import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'
import { IconType } from 'react-icons'
import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
} from 'react-icons/md'
import AnchorButton, { AnchorButtonType } from './internal/AnchorButton'

export type ClickyType = AnchorButtonType & {
  readonly as?: 'text' | 'icon' | 'icon-text' | 'text-icon'
  readonly freeze?: boolean
  readonly icon?: IconType | (() => JSX.Element)
}

const ClickyExplore = forwardRef<HTMLElement, ClickyType>(
  function ClickyExplore(props: ClickyType, ref) {
    const {
      as = 'icon-text',
      children,
      className,
      freeze,
      icon,
      ...rezt
    } = props
    const rest = { ref, ...rezt }

    const Icon = icon
      ? icon
      : className?.includes('motion-north')
      ? MdArrowUpward
      : className?.includes('motion-south')
      ? MdArrowDownward
      : className?.includes('motion-west')
      ? MdArrowBack
      : MdArrowForward

    return (
      <AnchorButton
        className={clsx(
          'group/clicky explore flex items-center gap-[1em]',
          !className?.includes('motion') && !freeze && 'motion-east',
          className,
        )}
        {...rest}
      >
        <span
          className={clsx(
            as === 'icon' && 'sr-only',
            as === 'icon-text' && 'order-2',
          )}
        >
          {children}
        </span>
        {Icon && as !== 'text' && (
          <span
            className={clsx(
              'flex h-12 w-12 items-center justify-center border transition-all duration-200',
              as === 'icon-text' && 'order-1 mr-2',
              as === 'text-icon' && 'order-2 ml-2',
            )}
          >
            <Icon role="presentation" />
          </span>
        )}
      </AnchorButton>
    )
  },
)

/**
 * ClickyExplore is a wrapper around `AnchorButton` that is context aware to provide
 * predefined layouts, states, motions with icons and classes.
 *
 * ClickyExplore  can be rendered `as: text | icon | icon-text | text-icon`. It adjusts
 * layouts handled by the `btn` css class. It's the option you want when looking for a secondary style button with a transparent background.
 *
 * @see [AnchorButton](https://github.com/vangst/oogmerk/blob/main/apps/web/components/navigation/internal/AnchorButton.tsx)
 * @see [Clicky Story](https://uat.vangst.com/oranje/stories?id=Components_Navigation_Clicky)
 *
 * @example
          <ClickyExplore as="text-icon" icon={MdClose} className="motion-west">
             A clear arrow button and text
          </ClickyExplore>
 *
 */
export default ClickyExplore
