import clsx from '@vangst/lib/clsx'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import Image from 'next/image'
import { memo } from 'react'
import { AsTypeUnion } from '../../domains/nodes/RendererFactory'

type PropsType = React.ImgHTMLAttributes<HTMLImageElement> & {
  readonly as?: AsTypeUnion
  readonly alt: string
  readonly fill?: boolean
  readonly width?: number
  readonly height?: number
}

function Avatar({
  alt,
  as,
  fill,
  width,
  height,
  className,
  src,
  ...rest
}: PropsType) {
  const hasSrc = !isEmptyOrNil(src)
  if (hasSrc && src)
    return (
      <Image
        alt={alt}
        className={clsx(
          as === 'face-card' && 'block h-auto max-w-full',
          as !== 'face-card' && 'h-12 w-12 rounded-full',
          'flex max-w-full items-center justify-center bg-none object-cover uppercase',
          // NOTE: when mood-grey propogates down to here, it causes SOME avatars to render as all black ¯\_(ツ)_/¯
          className?.replace('mood-grey', ''),
        )}
        src={
          src ||
          'https://vangst-direct-upload.s3-accelerate.amazonaws.com/v2VHe8q1BxzbUns8wjErgk.jpeg'
        }
        fill={as === 'face-card'}
        width={as === 'face-card' ? undefined : width || 48}
        height={as === 'face-card' ? undefined : height || 48}
        quality={100}
      />
    )
  return (
    <span
      className={clsx(
        as === 'face-card' && 'block h-full max-w-full',
        as !== 'face-card' && 'h-12 w-12 rounded-full',
        'mood-grey-light flex max-w-full items-center justify-center uppercase',
        className,
      )}
      {...rest}
    >
      {alt}
    </span>
  )
}

/**
 * A rounded image typically associated with a person, place or thing with a
 * fallback displaying the `alt` tag.
 *
 * Avatar has an internal dependency on `next/image`
 *
 * @see [next/image](https://nextjs.org/docs/api-reference/next/image)
 *
 * @example
 * <Avatar
 *   alt="Bear"
 *   className="h-20 w-20"
 *   src={unsplash.daily('moose')}
 *   title="moose"
 *   height={80}
 *   width={80}
 * />
 * <Avatar alt="CO" src={unsplash.daily('bear')} />
 * <Avatar alt="..." />
 * <Avatar alt="CO" />
 * <Avatar alt="CO" className="mood-blue-dark" />
 */
export default memo(Avatar)
