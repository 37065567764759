import { Menu } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import useCompany, {
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import useFollow from '@vangst/services/oogst/company/useFollow'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { memo, useState } from 'react'
import { MdErrorOutline, MdMoreVert } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import {
  MenuItemClicky,
  MenuItemClickyKid,
  MenuItems,
} from '../../../components/navigation/Menus'
import DialogNewJobOrder from '../../job/feedback/DialogNewJobOrder'

type PropsType = {
  readonly variables: CompanyVars
  readonly options?: CompanyOptions
} & React.HTMLAttributes<HTMLDivElement>

function CompanyActionsMenu(props: PropsType) {
  const { className, variables, options } = props
  const [showAlert, setShowAlert] = useState(false)
  const [showJobForm, setShowJobForm] = useState(false)
  const { authenticated, authenticating, viewer } = useViewer()
  const unauthenticated = !authenticated && !authenticating

  const { data } = useCompany(variables, options)
  const company = data?.getClient

  const { followCompany, unfollowCompany } = useFollow(company)
  const show = () => setShowAlert(true)
  const hide = () => setShowAlert(false)

  const handleClickUnfollow = () => {
    unfollowCompany?.()
    hide()
  }

  return (
    <>
      <Menu as="div" className={clsx('relative inline-flex', className)}>
        <Menu.Button className="link-blue flex h-8 w-8 items-center justify-end outline-none">
          <MdMoreVert />
          <span className="sr-only">Options</span>
        </Menu.Button>
        <Menu.Items as={MenuItems} className="!-right-2">
          {unauthenticated && (
            <Menu.Item>
              <MenuItemClicky activate href={Routes.SIGN_UP}>
                <MenuItemClickyKid label="Sign Up to Follow" />
              </MenuItemClicky>
            </Menu.Item>
          )}
          {followCompany && (
            <Menu.Item>
              <MenuItemClicky onClick={followCompany}>
                <MenuItemClickyKid label="Follow" />
              </MenuItemClicky>
            </Menu.Item>
          )}
          {unfollowCompany && (
            <>
              <Menu.Item>
                <MenuItemClicky onClick={show}>
                  <MenuItemClickyKid label="Unfollow" />
                </MenuItemClicky>
              </Menu.Item>
            </>
          )}

          {viewer?.permissions?.internalUser && (
            <Menu.Item>
              <MenuItemClicky onClick={() => setShowJobForm(true)}>
                <MenuItemClickyKid label="New Job Order" />
              </MenuItemClicky>
            </Menu.Item>
          )}
          {company?.permissions?.edit && (
            <Menu.Item>
              <MenuItemClicky activate href={company?.routes?.jobsNew || '#'}>
                <MenuItemClickyKid label="Post Job" />
              </MenuItemClicky>
            </Menu.Item>
          )}
          {company?.permissions?.edit && (
            <Menu.Item>
              <MenuItemClicky activate href={company?.routes?.edit || '#'}>
                <MenuItemClickyKid label="Edit Profile" />
              </MenuItemClicky>
            </Menu.Item>
          )}
          <Menu.Item>
            <MenuItemClicky activate href={company?.routes?.detail || '#'}>
              <MenuItemClickyKid label="View Profile" />
            </MenuItemClicky>
          </Menu.Item>
        </Menu.Items>
      </Menu>
      {showAlert && (
        <DialogModal onClose={hide} open>
          <DialogModal.Panel icon={MdErrorOutline} title="Unfollow?">
            <MdErrorOutline className="text-orange" size="3rem" />
            <DialogModal.Description className="my-8">
              Click okay to remove this connection from your network. We won't
              notify the other party of the disconnection. This profile will no
              longer display as a connection on your profile.
            </DialogModal.Description>
            <div className="flex w-full items-center justify-center gap-4">
              <ClickyBtn className="mood-white w-full" onClick={hide}>
                Cancel
              </ClickyBtn>
              <ClickyBtn
                className="mood-orange w-full"
                onClick={handleClickUnfollow}
              >
                OK
              </ClickyBtn>
            </div>
          </DialogModal.Panel>
        </DialogModal>
      )}
      {showJobForm && (
        <DialogNewJobOrder
          associatedClientDisplayname={variables.displayname}
          handleCancel={() => setShowJobForm(false)}
        />
      )}
    </>
  )
}

/**
 * Used in company renderers for navigation, relationship management and other call to actions.
 *
 * @example
 * <CompanyActionsMenu variables={{ displayname: 'vangst' }} />
 */
export default memo(CompanyActionsMenu)
