import clsx from '@vangst/lib/clsx'
import useMemberConnections from '@vangst/services/oogst/member/useMemberConnections'
import {
  ConnectionActorEnum,
  ConnectionStatusFilterEnum,
  MemberFragment,
  Member_ConnectionsFragment,
} from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { memo } from 'react'
import VangstMark from '../../components/assets/marks/VangstMark'
import Busy from '../../components/feedback/Busy'
import ClickyExplore from '../../components/navigation/ClickyExplore'
import MemberRenderer from '../member/MemberRenderer'
import useMessages from './useMessages'

type PropsType = React.HTMLAttributes<HTMLDivElement>

function ChatPromotion(props: PropsType) {
  const { className, ...rest } = props
  const { authenticated, viewer } = useViewer()
  const { handleToggleWidget } = useMessages({ isWidget: true })

  const { data, isFetching, isZero } = useMemberConnections(
    {
      connectionResourceType: ConnectionActorEnum.User,
      first: 3,
      status: ConnectionStatusFilterEnum.Accepted,
      username: viewer?.username ?? '',
    },
    { enabled: authenticated },
  )

  const dataConnections = data?.pages?.[0]?.getUser
  return (
    <div
      className={clsx(
        'mood-green-white relative flex  w-full flex-col gap-4 border border-green p-4 sm:col-span-2',
        className,
      )}
      {...rest}
    >
      {authenticated === true && dataConnections != null && (
        <PromoteAuthed
          connectionsRoute={viewer?.routes?.connections as Routes}
          data={dataConnections}
          handleToggleWidget={handleToggleWidget}
          isFetching={isFetching}
          isZero={isZero}
        />
      )}

      {authenticated === false && <PromoteUnauthed />}
    </div>
  )
}

const PromoteAuthed = ({
  connectionsRoute,
  data,
  handleToggleWidget,
  isFetching,
  isZero,
}: {
  connectionsRoute: Routes
  data: Member_ConnectionsFragment
  handleToggleWidget: (state: 'hide' | 'show') => void
  isFetching: boolean
  isZero?: boolean
}) => {
  const connections = data?.connections?.edges ?? []
  return (
    <>
      <Title>Start The Conversation</Title>
      <h3 className="font-600 capitalize">
        You can now message your connections on Vangst!
      </h3>

      <p className="text-sm">
        A great way to get more involved in the cannabis community is to start
        engaging with fellow Vangst community members.
      </p>
      {!isZero && !isFetching && (
        <p className="text-sm">Click on an avatar below to start chatting!</p>
      )}
      {isFetching && <Busy />}
      {!isZero && !isFetching && (
        <ul className="flow-x-xs">
          {connections.map((con) => (
            <li key={con?.node?.id}>
              <MemberRenderer
                as="chavatar"
                variables={{
                  username: (con?.node as MemberFragment)?.username || '',
                }}
              />
            </li>
          ))}
        </ul>
      )}
      {isZero && !isFetching && (
        <span className="text-sm">
          Looks like you haven't connected with any members on Vangst yet. In
          order to strike up a conversation, search for members within the
          Vangst community and send a connection request! Once they've accepted,
          that member will show up on your profile under Connections.
        </span>
      )}
      <ClickyExplore
        as="text-icon"
        className="mt-auto max-w-76"
        href={connections?.length > 0 ? connectionsRoute : Routes.MEMBERS}
        onClick={() => handleToggleWidget('hide')}
      >
        {connections.length > 0 ? 'View Connections' : 'Make Connections'}
      </ClickyExplore>
    </>
  )
}

const PromoteUnauthed = () => (
  <>
    <Title>Connect with other members</Title>
    <h3 className="font-600 capitalize">
      Engage with the community by messaging members you are connected with.
    </h3>
    <p className="text-sm">
      The best way to get involved in the cannabis community is to start
      engaging with fellow Vangst community members.
    </p>
    <span className="text-sm">
      In order to strike up a conversation, sign up to become a Vangst member,
      then search for other members within the Vangst community and send a
      connection request! Once they've accepted, that member will show up on
      your profile under Connections and you can send a message.
    </span>
    <ClickyExplore
      as="text-icon"
      className="mt-auto max-w-76"
      href={Routes.SIGN_UP + '?utm=chattin'}
    >
      Join Vangst To Connect
    </ClickyExplore>
  </>
)

const Title = (props: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h2 className="flex items-center gap-4 text-lg font-600 capitalize text-orange">
    <span className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
      <VangstMark className="h-10 w-10" />
    </span>
    <span>{props.children}</span>
  </h2>
)

export default memo(ChatPromotion)
