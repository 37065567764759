import { useQueryClient } from '@tanstack/react-query'
import clsx from '@vangst/lib/clsx'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import titleize from '@vangst/lib/titleize'
import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import useUpdatePlacement from '@vangst/services/oogst/company/useUpdatePlacement'
import {
  MemberComputed,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { MdEdit, MdVisibility } from 'react-icons/md'
import {
  Company_JobApplicationFragment,
  PlacementStatusEnum,
} from '../../../../../packages/services/oogst/types'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import DialogRequestInterview from '../../company/feedback/DialogRequestInterview'
import DialogContact from '../../job/feedback/DialogContact'
import Connect from '../Connect'
import GuiltBox from './GuiltBox'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPostingId?: string
  readonly indicateInterest?: React.ReactNode
  readonly application?: Company_JobApplicationFragment
  readonly member?: MemberComputed
  readonly options?: MemberOptions
  readonly title: string
  readonly variables: MemberVars
}

function MemberHeader(props: PropsType) {
  const {
    className,
    indicateInterest,
    application,
    member,
    options,
    title,
    variables,
    ...rest
  } = props
  const { viewer } = useViewer()
  const [showContactForm, setShowContactForm] = useState(false)
  const [interviewDialog, setInterviewDialog] = useState(false)
  const queryClient = useQueryClient()
  const { mutate } = useUpdatePlacement(queryClient, {
    onSettled: () => {
      setInterviewDialog(true)
    },
  })
  const { pathname } = useRouter()

  const isCurrentUser = member?.isCurrentUser
  const loading = member == null ? '•••' : undefined
  const percent = member?.candidate?.profilePercentComplete ?? 0
  const fullName = loading ?? member?.fullName ?? ''
  const avatar = !isEmptyOrNil(member?.avatarUrl)
    ? member?.avatarUrl
    : Routes?.GENERIC_USER_AVATAR

  // placement status update stuff:
  const level = application?.jobPosting?.level
  const placement = member?.candidate?.placements?.find(
    (p) => p.jobPosting.slug === application?.jobPosting?.slug,
  )

  const showStatusActions =
    placement?.permissions?.update &&
    application &&
    viewer?.isCompany &&
    level &&
    level > 1

  const submit = useCallback(
    (status: any) => {
      const placementId = placement?.id
      if (!placementId) return
      mutate({ input: { id: placementId, status } })
    },
    [mutate, placement?.id],
  )

  const handlePendingInterview = useCallback(() => {
    submit(PlacementStatusEnum.PendingNeedsInterview)
  }, [submit])

  return (
    <header className={clsx('mood-white', className)} {...rest}>
      <div className="contain-c template-content-aside sm:h-60">
        <div className="contain-xy flex w-full items-center justify-start gap-4 sm:h-full ">
          <div className="relative h-24 w-24 shrink-0 rounded-full border-2 border-white sm:top-6 sm:h-52 sm:w-52">
            <Image
              alt={`Profile picture of ${fullName}`}
              className="h-auto max-w-full rounded-full object-cover"
              fill
              sizes="40em"
              src={avatar as string}
            />
          </div>
          <div className="flow-y-xs w-full">
            <h1 className="font-700 capitalize heading-md">{fullName}</h1>
            {indicateInterest}
            <div className="flow-x-xs items-center">
              {member?.permissions?.hasRecruiterLabel && (
                <span className="max-w-fit rounded-full bg-white px-4 py-2 text-sm uppercase text-orange">
                  Recruiter
                </span>
              )}
              <div>
                <div className="flow-x-sm">
                  {placement != null &&
                    placement?.status != null &&
                    /applications/gi.test(pathname) && (
                      <span>
                        Current Status:{' '}
                        {titleize(placement?.status?.replace('_', ' ') || '')}
                      </span>
                    )}
                  {viewer?.isCompany &&
                    /applications/gi.test(pathname) &&
                    placement?.jobPosting?.permissions?.readPlacements && (
                      <ClickyLink
                        className="link-orange underline"
                        onClick={() => setShowContactForm(true)}
                      >
                        Request Additional Info
                      </ClickyLink>
                    )}
                </div>
                {showStatusActions && (
                  <div className="grid-two-up mt-4 space-x-2 sm:flex">
                    {placement?.status !== PlacementStatusEnum.Approved && (
                      <ClickyBtn
                        onClick={() => submit(PlacementStatusEnum.Approved)}
                        className="btn mood-green w-36 font-700 hocus:border-orange"
                      >
                        Approve Candidate
                      </ClickyBtn>
                    )}
                    {level &&
                      level > 2 &&
                      placement?.status === PlacementStatusEnum.Pending && (
                        <ClickyBtn
                          onClick={handlePendingInterview}
                          className="btn mood-blue w-40 font-700 hocus:border-orange"
                        >
                          Pending Needs Interview
                        </ClickyBtn>
                      )}
                    {placement?.status !== PlacementStatusEnum.Denied && (
                      <ClickyBtn
                        onClick={() => submit(PlacementStatusEnum.Denied)}
                        className="btn mood-red w-40 font-700 hocus:border-orange"
                      >
                        Deny Candidate
                      </ClickyBtn>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* We don't want client users to see connection/messaging stuff when viewing an application: */}
        {(viewer?.permissions?.internalUser ||
          !/applications/gi.test(pathname)) && (
          <aside className="contain-y mood-grey-lightest grid content-center gap-2 border-b border-b-grey">
            <div className="flow-y-xs px-4">
              {loading ? null : isCurrentUser ? (
                <GuiltBox href={member?.routes?.edit ?? ''} percent={percent} />
              ) : (
                <>
                  <Connect
                    at="detail"
                    variables={variables}
                    options={options}
                  />

                  {viewer?.permissions?.internalUser && (
                    <div className="flow-x-sm">
                      {pathname.includes('applications') && (
                        <ClickyLink
                          as="icon-text"
                          className="link-orange text-sm underline"
                          icon={MdVisibility}
                          href={member?.routes?.detail ?? ''}
                        >
                          View Profile
                        </ClickyLink>
                      )}
                      <ClickyLink
                        as="icon-text"
                        className="link-orange text-sm underline"
                        href={member?.routes?.edit ?? ''}
                        icon={MdEdit}
                      >
                        Edit Profile
                      </ClickyLink>
                    </div>
                  )}
                </>
              )}
            </div>
          </aside>
        )}
      </div>
      {showContactForm && (
        <DialogContact
          description="Let us know how we can help!"
          email={viewer?.email ?? ''}
          firstName={viewer?.firstName ?? ''}
          handleCancel={() => setShowContactForm(false)}
          lastName={viewer?.lastName ?? ''}
          phone={viewer?.phoneNumber ?? ''}
          state={viewer?.location?.state ?? ''}
          hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_GIGS_SUPPORT}
          defaultMessageValue={`I would like to know more about ${member?.fullName}`}
          title="Request More Info"
        />
      )}
      {interviewDialog && (
        <DialogRequestInterview
          handleCancel={() => setInterviewDialog(false)}
        />
      )}
    </header>
  )
}

export default MemberHeader
