import Routes from '@vangst/services/routes'
import ClickyLink, {
  ClickyType,
} from '../../../components/navigation/ClickyLink'

const GoLink = (props: ClickyType) => (
  <ClickyLink className="link-orange underline" {...props} />
)

/**
 * Questions? Please contact our support staff for more help.
 */
export function GoContactSupport() {
  return (
    <span className="text-sm">
      Questions? Please{' '}
      <GoLink href={Routes.ABOUT_CONTACT}>contact our support staff</GoLink> for
      more help.
    </span>
  )
}

/**
 * Forgot Password?
 */
export function GoForgotPassword() {
  return (
    <span className="text-sm">
      <GoLink href={Routes.MEMBER_FORGOT_PASSWORD}>Forgot Password?</GoLink>
    </span>
  )
}

/**
 * Already have an account? Sign In
 */
export function GoSignIn() {
  return (
    <span className="text-sm">
      Already have an account? <GoLink href={Routes.SIGN_IN}>Sign In</GoLink>
    </span>
  )
}

/**
 * Don't have an account? Sign Up
 */
export function GoSignUp() {
  return (
    <span className="text-sm">
      Don’t have an account? <GoLink href={Routes.SIGN_UP}>Sign up</GoLink>
    </span>
  )
}

/**
 * Would you like to try and Create an account?
 */
export function GoSignUpPolite() {
  return (
    <span className="text-sm">
      Would you like to try and{' '}
      <GoLink href={Routes.SIGN_UP}>Create an account?</GoLink>
    </span>
  )
}

/**
 * By continuing you agree to our Terms and Conditions
 */
export function GoTermsAgreement() {
  return (
    <span className="text-sm">
      By continuing, you agree to our{' '}
      <GoLink
        href={Routes.LEGAL_TERMS}
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms and Conditions
      </GoLink>
    </span>
  )
}
