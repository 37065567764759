import titleize from '@vangst/lib/titleize'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import {
  AtsRecordTypeEnum,
  JobApplicationStatusEnum,
  JobPostingAtsStatusEnum,
  JobPostingInput,
  PlacementStatusEnum,
} from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { clsx } from 'clsx'
import { memo, useState } from 'react'
import {
  MdEdit,
  MdMoreHoriz,
  MdOutlineExpandMore,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import {
  emptyText,
  TableRowSummary,
  TableSummaryDetailList,
} from '../../application/TableRow'
import useParams from '../../application/useParams'
import DialogEditJob from '../feedback/DialogEditJob'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly jobPosting?: JobPostingComputed
  readonly title: string
  readonly uri: string
}
function JobPostingTableRowSummary(props: PropsType) {
  const { viewer } = useViewer()
  const [jobFormIsOpen, setJobFormIsOpen] = useState(false)
  const { jobPosting, title, uri } = props
  const loading = jobPosting == null ? <MdMoreHoriz /> : undefined

  const jobPostingLevel = jobPosting?.gigsInfo?.level ?? emptyText

  const counts = jobPosting?.placementStatusCounts ?? []
  const getStatusCount = (status: PlacementStatusEnum) =>
    counts.find((item) => item?.status === status)?.count ?? 0

  const appCounts = jobPosting?.jobApplicationStatusCounts ?? []
  const getAppStatusCount = (status: JobApplicationStatusEnum) =>
    appCounts.find((item) => item?.status === status)?.count ?? 0

  const numPlaced =
    jobPosting?.placementStatusCounts?.find(
      (p) => p.status === PlacementStatusEnum.Active,
    )?.count ?? 0
  const totalPlacements = jobPosting?.quantity ?? 0

  const stats = [
    { title: 'Roles Filled', value: `${numPlaced}/${totalPlacements}` },
    {
      title: 'Active',
      value: getStatusCount(PlacementStatusEnum.Active),
    },
    {
      title: 'Onboarding',
      value: getStatusCount(PlacementStatusEnum.Onboarding),
    },
    {
      title: 'Onboarded',
      value: getStatusCount(PlacementStatusEnum.OnboardingFinished),
    },
    {
      title: 'Scheduled',
      value: getAppStatusCount(JobApplicationStatusEnum.ScreeningScheduled),
    },
  ]

  const highlight =
    viewer?.permissions?.internalUser &&
    jobPosting?.placements?.find(
      (p) => p.status === PlacementStatusEnum.Approved,
    )
  const { toggleParams } = useParams()
  const isGigs =
    jobPosting?.atsRecordType === AtsRecordTypeEnum.BoutiqueGigs ||
    jobPosting?.atsStatus === JobPostingAtsStatusEnum.ReadyToPublish
  const company = isGigs ? jobPosting?.associatedClient : jobPosting?.client
  return (
    <TableRowSummary
      toggleParams={() =>
        toggleParams({ key: 'open', value: jobPosting?.title })
      }
      className={clsx(highlight && 'bg-green/20')}
    >
      <div className="flex w-full">
        <span className="grid w-88 grid-cols-2">
          <TableSummaryDetailList title="Job Title">
            <span className="flow-x-xs">
              <span className="mood-grey-lightest flex h-4 w-4 items-center justify-center rounded-full text-[10px]">
                {jobPosting?.atsRecordType === AtsRecordTypeEnum.BoutiqueGigs
                  ? 'G'
                  : 'P'}
              </span>
              <span className="w-4">
                {jobPosting == null && <MdMoreHoriz className="h-4 w-4" />}
                {jobPosting?.isPublished === false && (
                  <span
                    title="Unpublished Job"
                    className="flex h-4 w-4 items-center justify-center rounded-full bg-red/50"
                  >
                    <MdOutlineVisibilityOff className="h-3 w-3" />
                  </span>
                )}
                {jobPosting?.isPublished === true && (
                  <span
                    title="Published Job"
                    className="mood-green-tint flex h-4 w-4 items-center justify-center rounded-full"
                  >
                    <MdOutlineVisibility className="h-3 w-3" />
                  </span>
                )}
              </span>
              <ClickyLink href={uri} className="link-orange w-28 truncate">
                {title}
              </ClickyLink>
            </span>
          </TableSummaryDetailList>
          <TableSummaryDetailList title="Company">
            <ClickyLink
              href={Routes.COMPANIES_DETAIL.replace(
                ':displayname',
                company?.displayname ?? '',
              )}
              className="link-orange"
            >
              <span className="flow-x-xs items-center">
                <Avatar
                  alt={`Avatar of ${company?.name ?? 'Unknown'}`}
                  className="mood-grey h-4 w-4"
                  src={company?.avatarUrl ?? Routes.GENERIC_USER_AVATAR}
                  title={title}
                  role="presentation"
                />
                <span className="w-32 truncate">
                  {loading ?? company?.name ?? ''}
                </span>
              </span>
            </ClickyLink>
          </TableSummaryDetailList>
        </span>
        <span className="flow-x-xs mr-4">
          <TableSummaryDetailList title="Level">
            <span className="w-12 text-[12px]">{jobPostingLevel}</span>
          </TableSummaryDetailList>
          <TableSummaryDetailList title="Status">
            <span className="w-12 truncate text-[12px]">
              {jobPosting?.atsStatus != null
                ? titleize(jobPosting?.atsStatus)
                : emptyText}
            </span>
          </TableSummaryDetailList>
        </span>
        <span className="flow-x-xs mr-4">
          <TableSummaryDetailList title="Location">
            <span className="w-24 truncate">
              {loading ??
                (jobPosting?.isRemote ? 'Remote' : jobPosting?.citystate) ??
                emptyText}
            </span>
          </TableSummaryDetailList>
          <TableSummaryDetailList title="Posted At">
            <span title="Date Posted" className="w-24 truncate">
              {loading ?? jobPosting?.postedAtWords ?? emptyText}
            </span>
          </TableSummaryDetailList>
          <TableSummaryDetailList title="Applications">
            <span>{jobPosting?.countJobApplicationsTotal ?? 0} </span>
            {jobPosting?.countJobApplicationsTotal != null &&
              jobPosting.countJobApplicationsTotal > 0 && (
                <ClickyLink
                  className="link-orange ml-2"
                  href={jobPosting?.routes?.jobsApplications}
                >
                  View
                </ClickyLink>
              )}
          </TableSummaryDetailList>
          <TableSummaryDetailList title="Placements">
            <span>{jobPosting?.placements?.length ?? 0} </span>
            {jobPosting?.placements != null &&
              jobPosting.placements?.length > 0 && (
                <ClickyLink
                  className="link-orange ml-2"
                  href={jobPosting?.routes?.jobsPlacementManage}
                >
                  Manage
                </ClickyLink>
              )}
          </TableSummaryDetailList>
        </span>

        <span className="ml-auto grid grid-cols-5 gap-2">
          {(jobPosting?.permissions.internalUser
            ? stats
            : stats.filter((stat) => /(Active|Placements)/gi.test(stat.title))
          ).map((stat) => (
            <TableSummaryDetailList
              key={stat.title}
              title={stat.title}
              className="items-center"
            >
              <span title={stat.title}>{stat.value}</span>
            </TableSummaryDetailList>
          ))}
        </span>
      </div>

      {/* Right Side */}
      {jobPosting?.permissions.edit && (
        <div className="flow-x-xs ml-auto items-center">
          <ClickyLink
            className="link-orange mt-1 h-4 w-4"
            as="icon"
            // onClick={() => setJobFormIsOpen(true)}
            href={jobPosting?.routes?.jobsEdit}
            icon={MdEdit}
          />
          <MdOutlineExpandMore className="ml-auto transition-transform group-open/details:rotate-180" />
        </div>
      )}

      {jobFormIsOpen && (
        <DialogEditJob
          jobPosting={jobPosting as JobPostingInput}
          handleCancel={() => setJobFormIsOpen(false)}
        />
      )}
    </TableRowSummary>
  )
}

export default memo(JobPostingTableRowSummary)
