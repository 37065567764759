import {
  CompanyComputed,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly title: string
  readonly uri: string
  readonly variables: CompanyVars
}

function CompanyHeader(props: PropsType) {
  const { company, title, uri, variables } = props

  return (
    <h2 className="flex items-center heading-md">
      <span className="inline-flex min-h-20 min-w-20">
        <Avatar
          className="mood-blue h-20 w-20 text-lg"
          alt={company?.initials ?? '...'}
          src={company?.avatarUrl || undefined}
          width={80}
          height={80}
          role="presentation"
        />
      </span>
      <span className="ml-4 flex flex-col">
        <span>{title}</span>
        <ClickyLink className="text-sm hocus:text-blue" href={uri}>
          @{variables.displayname}
        </ClickyLink>
      </span>
    </h2>
  )
}

export default CompanyHeader
