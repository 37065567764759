import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import toBoolean from '@vangst/lib/toBoolean'
import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import useCompanyEdit from '@vangst/services/oogst/company/useCompanyEdit'
import useJobPosting from '@vangst/services/oogst/jobPosting/useJobPosting'
import {
  AtsRecordTypeEnum,
  CredentialFragment,
  JobPostingAtsStatusEnum,
  JobPostingFragment,
  JobPostingInput,
  LicenseFragment,
  TeamEnum,
  useCompanyCredentialsCatalogueQuery,
} from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { memo, useEffect, useState } from 'react'
import { Controller, FormProvider, useFieldArray } from 'react-hook-form'
import { MdAddCircle, MdError, MdOutlineInfo } from 'react-icons/md'
import { MemberFragment } from '../../../../packages/services/oogst/types'
import AlertText from '../../components/feedback/AlertText'
import Busy from '../../components/feedback/Busy'
import FilterPopover from '../../components/feedback/FilterPopover'
import Tooltip from '../../components/feedback/Tooltip'
import Input from '../../components/forms/Input'
import Label from '../../components/forms/Label'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import ClickyLink from '../../components/navigation/ClickyLink'
import QueryErrorFallback from '../application/feedback/QueryErrorFallback'
import ChooseBoolean from '../application/forms/ChooseBoolean'
import InputGeolocation from '../application/forms/InputGeolocation'
import validations from '../application/validations'
import Editor from '../editor/Editor'
import ChooseClientUsersCombo from '../member/forms/ChooseClientUsersCombo'
import ChooseEmploymentTypes from '../member/forms/ChooseEmploymentTypes'
import SelectClientUser from '../member/forms/SelectClientUser'
import ChooseByRoleCombo from '../recruiter/forms/ChooseByRoleCombo'
import ChooseElasticSearch from '../search/forms/ChooseElasticSearch'
import DialogContact from './feedback/DialogContact'
import ChooseAtsRecordTypeRadio from './forms/ChooseAtsRecordTypeRadio'
import FieldsCredentialsJob from './forms/FieldsCredentialsJob'
import FieldsJobSalaryRange from './forms/FieldsJobSalaryRange'
import FieldsLicensesJob from './forms/FieldsLicensesJob'
import SelectAtsStatus from './forms/SelectAtsStatus'
import SelectJobSalaryPeriod from './forms/SelectJobSalaryPeriod'
import SelectLevel from './forms/SelectLevel'
import SelectScreening from './forms/SelectScreening'
import useFormEditJob from './forms/useFormEditJob'
import DeleteJobPosting from './views/DeleteJobPosting'

type PropsType = React.HTMLAttributes<HTMLFormElement> & {
  readonly displayname?: string
  readonly isDialog?: boolean
  readonly focusRef?: any
  readonly jobPostingInput?: JobPostingInput & {
    readonly recruiters: any
    readonly accountExecs: any
    readonly timeSheetManagers: any
  }
  readonly licenses: LicenseFragment[]
}

function JobPostingEditForm(props: PropsType) {
  const { displayname, isDialog, jobPostingInput, focusRef } = props
  const { viewer } = useViewer()
  const [showDateError, setShowDateError] = useState(false)
  const [showAssociatedClientError, setShowAssociatedClientError] =
    useState(false)
  const [remote, setRemote] = useState(props?.jobPostingInput?.isRemote)
  const [showServicesModal, setShowServicesModal] = useState(false)
  const [toggle, setToggle] = useState(true)
  const [clientDisplayname, setClientDisplayname] = useState(displayname)
  const jobId = jobPostingInput?.id

  const jobData = useJobPosting({ id: jobId || '' }, { enabled: jobId != null })
  const jobPosting = jobData?.data?.getJobPosting as JobPostingFragment

  const { data: companyData } = useCompanyEdit(
    { displayname: clientDisplayname || '' },
    { enabled: clientDisplayname != null },
  )
  const credentialQueryVal = jobPosting?.associatedClient
    ? 'vangst'
    : clientDisplayname
  const { data: credData } = useCompanyCredentialsCatalogueQuery(
    { displayname: credentialQueryVal ?? '' },
    { enabled: credentialQueryVal != null },
  )

  const { error, methods, reset, saving, submit } = useFormEditJob({
    displayname: clientDisplayname,
    jobPostingInput,
    jobPosting,
    setShowDateError,
    setShowAssociatedClientError,
  })

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = methods

  // Callback version of watch, needed to trigger a re-render of the page via the setRemote toggle
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'isRemote') {
        setRemote(toBoolean(value.isRemote))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, remote])

  // when the associatedClient changes, refetches company data to repopulate the time sheet managers field
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'associatedClient') {
        setClientDisplayname(value.associatedClient as string)
        setShowAssociatedClientError(false)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, clientDisplayname])

  const {
    fields: fieldsLicenses,
    append: appendLicense,
    remove: removeLicense,
  } = useFieldArray({ control, name: 'requiredLicenses' })
  const {
    fields: fieldsCredentials,
    append: appendCredential,
    remove: removeCredential,
  } = useFieldArray({ control, name: 'recommendedCredentials' })

  if (jobId && !jobPosting) {
    return <Busy />
  }

  const isJobOrder = jobPosting && !!jobPosting?.associatedClient
  const credentials = credData?.getCompanyCredentialsCatalogue ?? []

  const recruitersValue = getValues('recruiterIds')
  const accountExecsValue = getValues('accountExecIds')
  const timeSheetManagersValue = getValues('timeSheetManagerIds')
  const accountsPayableValue =
    getValues('accountsPayableEmail') ||
    companyData?.getClient?.accountsPayableEmail

  const { ref: atsStatusRef, ...atsStatusProps } = register('atsStatus')
  const watchedAtsStatus = watch('atsStatus')

  // "fullJobOrder" means the status has moved past pending and we should apply full validations
  const fullJobOrder =
    jobPosting?.atsRecordType === AtsRecordTypeEnum.BoutiqueGigs &&
    watchedAtsStatus != JobPostingAtsStatusEnum.Pending &&
    watchedAtsStatus != JobPostingAtsStatusEnum.NoStage

  // This function determines which fields should be validated based on the job type and status
  const shouldValidate = (field: string) => {
    // always validate these fields if they're rendered on the form:
    if (
      [
        'title',
        'associatedClient',
        'quantity',
        'gigsInfo.kickoffCall',
        'accountExecIds',
      ].includes(field)
    ) {
      return true
    }

    const checkSharedFields = !isJobOrder || fullJobOrder
    // these fields are validated on both platform free jobs and non-pending job orders:
    if (
      checkSharedFields &&
      [
        'description',
        'responsibilities',
        'employmentTypes',
        'isRemote',
        'startDate',
        'salaryRange',
      ].includes(field)
    ) {
      return true
    }

    // these fields are only validated on job orders past the pending state:
    if (
      fullJobOrder &&
      [
        'syndicateIndeed',
        // 'gigsInfo.level',
        'primaryContactId',
        'quantity',
        'depositAmount',
        'gigsInfo.billRate',
        'gigsInfo.payRate',
        'gigsInfo.kickoffCall',
        'experience',
      ].includes(field)
    ) {
      return true
    }
    return false
  }

  // Gigs fields:
  const { ref: recruitersRef, ...recruitersProps } = register(`recruiterIds`)
  const { ref: accountExecsRef, ...accountExecsProps } =
    register(`accountExecIds`)
  const { ref: timeSheetManagersRef, ...timeSheetManagersProps } =
    register(`timeSheetManagerIds`)
  const { ref: levelRef, ...levelProps } = register(
    'gigsInfo.level',
    shouldValidate('gigsInfo.level') ? validations.generic : undefined,
  )
  const { ref: salaryPayPeriodRef, ...salaryPayPeriodProps } = register(
    'gigsInfo.salaryPayPeriod',
  )

  // Standard fields:
  const { ref: startDateRef, ...startDateProps } = register(
    'startDate',
    shouldValidate('startDate') ? validations.generic : undefined,
  )
  const { ref: endDateRef, ...endDateProps } = register('endDate')
  const { ref: employmentTypesRef, ...employmentTypesProps } = register(
    'employmentTypes',
    shouldValidate('employmentTypes') ? validations.generic : undefined,
  )
  const { ref: submitApplicationUrlRef, ...submitApplicationUrlProps } =
    register('submitApplicationUrl')

  let acountsPayableOptions = [] as MemberFragment[]
  // the accountsPayableEmail options include company users AND the company.accountsPayableEmail if it exists
  const companyAccountsPayableOption = companyData?.getClient
    ?.accountsPayableEmail
    ? [
        {
          firstName: 'Company',
          lastName:
            'Default ' + `(${companyData?.getClient?.accountsPayableEmail})`,
          email: companyData?.getClient?.accountsPayableEmail,
        },
      ]
    : []
  if (companyData?.getClient?.users) {
    acountsPayableOptions = [
      ...(companyData?.getClient?.users || []),
      ...(companyAccountsPayableOption as MemberFragment[]),
    ]
  }

  const watchedSalary = watch('salaryRange')

  const handleValueChange = (name: any, val: string[]) => {
    setValue(name, val)
    setToggle(!toggle)
  }
  const disableAtsStatus =
    jobPostingInput?.atsStatus == JobPostingAtsStatusEnum.Pending &&
    !viewer?.permissions?.publishJobOrders
  const enableAtsStatus =
    jobPostingInput?.atsStatus == JobPostingAtsStatusEnum.Pending &&
    viewer?.permissions?.publishJobOrders
  return (
    <FormProvider {...methods}>
      <form
        className="contain-c template-content-aside"
        onSubmit={handleSubmit(submit)}
        onReset={reset}
      >
        {showServicesModal && (
          <DialogContact
            email={viewer?.email ?? ''}
            phone={viewer?.phoneNumber ?? ''}
            state={viewer?.location?.state ?? ''}
            firstName={viewer?.firstName ?? ''}
            lastName={viewer?.lastName ?? ''}
            handleCancel={() => setShowServicesModal(false)}
            hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_SUPPORT}
          />
        )}
        <div className="contain-y mood-white w-full pt-0">
          <div className="flow-y-sm">
            {viewer?.permissions?.internalUser && (
              <fieldset>
                <section ref={focusRef} className="border-4 border-blue-light">
                  <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
                    Recruiter Info
                  </h2>
                  <p className="mood-grey-lightest flex h-12 items-center justify-end pr-4 text-sm font-700">
                    <span className="mr-2 pt-4 text-orange heading-md">*</span>
                    <span>Indicates a required field</span>
                  </p>
                  <div className="mood-grey-lightest flex w-full flex-col gap-8 p-4">
                    <div className="pl-4">
                      {(jobId || !isJobOrder) && (
                        <div className="gap-8 space-y-4">
                          <ChooseBoolean
                            label="Published on the site?"
                            name="isPublished"
                          />
                        </div>
                      )}
                    </div>
                    {isJobOrder && (
                      <div className="mood-grey-lightest flex w-full flex-col gap-8 p-4">
                        <div className="space-y-6">
                          <SelectAtsStatus
                            label="Job ATS Status"
                            defaultValue={JobPostingAtsStatusEnum.Pending}
                            reference={atsStatusRef}
                            {...atsStatusProps}
                            disabled={disableAtsStatus}
                          />
                          {disableAtsStatus && (
                            <span className="text-sm text-grey-dark">
                              (Status is locked until an account exec moves it
                              out of pending)
                            </span>
                          )}
                          {enableAtsStatus && (
                            <span className="text-sm text-grey-dark">
                              (Moving the job order out of "Pending" will
                              require additional fields to be filled out)
                            </span>
                          )}
                          <ChooseAtsRecordTypeRadio
                            required
                            recruitingJob={isJobOrder}
                            label="Job Record Type"
                            name="atsRecordType"
                          />
                          <div className="gap-8 space-y-4">
                            <ChooseBoolean
                              label="Syndicate To Indeed?"
                              name="syndicateIndeed"
                            />
                          </div>
                          {/* NOTE: this disabled dropdown is here just to give move visual explantion to users that job orders are locked to the vangst recruiting client */}
                          <SelectClientUser
                            users={[
                              {
                                id: 'default',
                                firstName: 'Vangst',
                                lastName: 'Recruiting',
                                email: 'default',
                              } as MemberFragment,
                            ]}
                            defaultValue="default"
                            disabled
                            className="pointer-events-none"
                            label="Company (locked)"
                            name="UI-Only-Field"
                          />

                          <SelectScreening
                            defaultValue={getValues('screeningId')}
                            error={errors?.screeningId?.message}
                            label="Select Screening"
                            {...register('screeningId')}
                          />
                        </div>

                        <span className="-mb-6 font-600 after:left-1 after:ml-1 after:scale-125 after:text-red after:content-['*']">
                          Associated Company
                        </span>
                        <ChooseElasticSearch
                          endpoint="searchClientsByRecruiters"
                          onChange={(e) => {
                            setValue('timeSheetManagerIds', [])
                            setValue('primaryContactId', '')
                            setValue('accountsPayableEmail', '')
                            // @ts-ignore - complaining about the type of e here
                            setValue('associatedClient', e)
                          }}
                        >
                          {clientDisplayname ||
                            'Which Company is this job for?'}
                        </ChooseElasticSearch>
                        {jobPosting?.timeSheetManagers &&
                          jobPosting.timeSheetManagers.length > 0 && (
                            <span className="-mt-6 text-sm text-red">
                              Note: changing the associated client will reset
                              Time Sheet Managers, Accounts Payable Email, and
                              Primary Contact
                            </span>
                          )}
                        <SelectLevel
                          label="Job Level"
                          reference={levelRef}
                          defaultValue={jobPostingInput?.gigsInfo?.level}
                          {...levelProps}
                        />
                        <SelectClientUser
                          users={acountsPayableOptions}
                          defaultValue={accountsPayableValue}
                          error={errors?.accountsPayableEmail?.message}
                          valueField="email"
                          label="Accounts Payable Email"
                          {...register('accountsPayableEmail')}
                        />

                        <SelectClientUser
                          users={companyData?.getClient?.users || []}
                          error={errors?.primaryContactId?.message}
                          defaultValue={getValues('primaryContactId')}
                          label="Primary Contact"
                          {...register(
                            'primaryContactId',
                            shouldValidate('primaryContactId')
                              ? validations.generic
                              : undefined,
                          )}
                        />

                        <Input
                          error={errors?.quantity?.message}
                          label="Number of Positions"
                          type="number"
                          required
                          {...register(
                            'quantity',
                            shouldValidate('quantity')
                              ? validations.generic
                              : undefined,
                          )}
                        />
                        <Input
                          error={errors?.depositAmount?.message}
                          label="Deposit Amount"
                          {...register(
                            'depositAmount',
                            shouldValidate('depositAmount')
                              ? validations.generic
                              : undefined,
                          )}
                        />
                        <Input
                          error={errors?.gigsInfo?.billRate?.message}
                          label="Bill Rate"
                          {...register(
                            'gigsInfo.billRate',
                            shouldValidate('gigsInfo.billRate')
                              ? validations.generic
                              : undefined,
                          )}
                        />
                        <Input
                          error={errors?.gigsInfo?.payRate?.message}
                          label="Pay Rate"
                          {...register(
                            'gigsInfo.payRate',
                            shouldValidate('gigsInfo.payRate')
                              ? validations.generic
                              : undefined,
                          )}
                        />
                        <SelectJobSalaryPeriod
                          error={errors?.gigsInfo?.salaryPayPeriod?.message}
                          isJobOrderForm={isJobOrder}
                          label="Pay Period"
                          reference={salaryPayPeriodRef}
                          {...salaryPayPeriodProps}
                        />

                        <ChooseBoolean
                          label="Include a Kickoff Call?"
                          name="gigsInfo.kickoffCall"
                          required={shouldValidate('gigsInfo.kickoffCall')}
                        />
                        <ChooseBoolean
                          label="Engagement Deposit?"
                          name="gigsInfo.engagementDeposit"
                        />
                        <div className="max-w-88 space-y-6">
                          <FilterPopover
                            isFiltering={!isEmptyOrNil(recruitersValue)}
                            label="Assigned Recruiters"
                          >
                            <ChooseByRoleCombo
                              label="Assigned Recruiters"
                              reference={recruitersRef}
                              setValue={setValue}
                              value={recruitersValue}
                              vars={{ isRecruiter: true }}
                              handleValueChange={handleValueChange}
                              // @ts-ignore
                              control={control}
                              {...recruitersProps}
                            />
                          </FilterPopover>

                          <FilterPopover
                            isFiltering={!isEmptyOrNil(accountExecsValue)}
                            label="Assigned Sales People"
                          >
                            <ChooseByRoleCombo
                              label="Assigned Sales People"
                              reference={accountExecsRef}
                              setValue={setValue}
                              value={accountExecsValue}
                              handleValueChange={handleValueChange}
                              vars={{ isAccountExec: true }}
                              teamEnum={TeamEnum.Sales}
                              // @ts-ignore
                              control={control}
                              {...accountExecsProps}
                            />
                          </FilterPopover>
                          <FilterPopover
                            isFiltering={!isEmptyOrNil(timeSheetManagersValue)}
                            label="Time Sheet Managers"
                          >
                            <ChooseClientUsersCombo
                              label="Time Sheet Managers"
                              reference={timeSheetManagersRef}
                              setValue={setValue}
                              value={timeSheetManagersValue}
                              handleValueChange={handleValueChange}
                              users={companyData?.getClient?.users || []}
                              // @ts-ignore
                              control={control}
                              {...timeSheetManagersProps}
                            />
                          </FilterPopover>
                        </div>

                        <Controller
                          render={({ field }) => (
                            <Editor
                              error={
                                errors?.gigsInfo?.additionalRequirements
                                  ?.message
                              }
                              label="Additional Requirements"
                              placeholder="(badges, licenses, etc...)"
                              {...field}
                            />
                          )}
                          name="gigsInfo.additionalRequirements"
                          control={control}
                          rules={{
                            validate: {
                              maxLength: validations.slateMaxLength,
                            },
                          }}
                        />
                        <Controller
                          render={({ field }) => (
                            <Editor
                              error={
                                errors?.gigsInfo?.parkingFacilityInformation
                                  ?.message
                              }
                              label="Parking Facility Information"
                              placeholder="(location, gate codes, etc...)"
                              {...field}
                            />
                          )}
                          name="gigsInfo.parkingFacilityInformation"
                          control={control}
                          rules={{
                            validate: {
                              maxLength: validations.slateMaxLength,
                            },
                          }}
                        />
                        <Controller
                          render={({ field }) => (
                            <Editor
                              error={errors?.gigsInfo?.specialRequests?.message}
                              label="Special Requests"
                              {...field}
                            />
                          )}
                          name="gigsInfo.specialRequests"
                          control={control}
                          rules={{
                            validate: {
                              maxLength: validations.slateMaxLength,
                            },
                          }}
                        />
                        <Controller
                          render={({ field }) => (
                            <Editor
                              error={errors?.gigsInfo?.dressCode?.message}
                              label="Dress Code"
                              {...field}
                            />
                          )}
                          name="gigsInfo.dressCode"
                          control={control}
                          rules={{
                            validate: {
                              maxLength: validations.slateMaxLength,
                            },
                          }}
                        />
                        <Input
                          error={errors?.submitApplicationUrl?.message}
                          label="Submit Application URL"
                          reference={submitApplicationUrlRef}
                          {...submitApplicationUrlProps}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </fieldset>
            )}
            <fieldset>
              <section>
                <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
                  Job Information
                </h2>

                <div className="mood-grey-lightest mt-4 flex w-full flex-col gap-8 p-4">
                  <Input
                    error={errors?.title?.message}
                    label="Title"
                    required
                    {...register('title', validations.maxLength80Required)}
                  />
                  <ChooseBoolean
                    label="Is this job remote?"
                    name="isRemote"
                    required={shouldValidate('isRemote')}
                  />

                  <div>
                    <InputGeolocation
                      error={errors?.location ? 'error' : null}
                      label={
                        remote
                          ? 'Required Candidate Location (if applicable)'
                          : 'Full Street Address'
                      }
                      setValue={setValue}
                      getValues={getValues}
                      formType={remote ? 'geocode' : 'fullAddress'}
                      location={jobPostingInput?.location}
                      register={register}
                      placeholder="Please enter your address"
                      required={!remote}
                    />
                    {!remote ? (
                      <div className="mt-2">
                        <p className="text-sm italic">
                          Begin typing your address and select an option from
                          the dropdown.
                        </p>
                        <p className="text-sm italic text-orange">
                          This address is used to calculate estimated commute
                          distance. It will not be visible to candidates on the
                          job posting.
                        </p>
                      </div>
                    ) : (
                      <p className="mt-2 text-sm italic">
                        Begin typing a city or state and select an option from
                        the dropdown.
                      </p>
                    )}
                  </div>
                </div>

                <div className="mood-grey-lightest mt-4 flex w-full flex-col gap-2 p-4">
                  <Label label="Licensing" />
                  <Tooltip
                    icon={MdOutlineInfo}
                    className="mood-grey-light mr-8 -translate-y-8"
                  >
                    <p>
                      Select a cannabis employment license: choose from various
                      licenses that are required to work legally in the cannabis
                      industry or perform functions of this job.
                    </p>
                  </Tooltip>
                  <div className="flow-y-sm -mt-8">
                    {fieldsLicenses.map((fieldsLicense: any, i) => {
                      return (
                        <FieldsLicensesJob
                          defaultValue={fieldsLicense}
                          index={i}
                          key={
                            fieldsLicense.id?.length
                              ? fieldsLicense.id
                              : `requiredLicenses[${i}]`
                          }
                          remove={() => removeLicense(i)}
                        />
                      )
                    })}
                  </div>
                  <ClickyLink
                    as="icon-text"
                    className="link-orange inline-flex items-center text-sm underline"
                    icon={MdAddCircle}
                    onClick={() =>
                      fieldsLicenses?.length === 0
                        ? // @ts-ignore
                          appendLicense({ id: '', licenseId: '' })
                        : // @ts-ignore
                          appendLicense({ id: '' })
                    }
                  >
                    <span>
                      {fieldsLicenses?.length === 0
                        ? 'List Available Licenses'
                        : 'Add Another License'}
                    </span>
                  </ClickyLink>
                </div>
                <div className="mood-grey-lightest mt-4 flex w-full flex-col gap-2 p-4">
                  <Label label="Recommend Trainings" />
                  <Tooltip
                    icon={MdOutlineInfo}
                    className="mood-grey-light mr-8 -translate-y-8"
                  >
                    <p>
                      Select a Vangst Training Credential you recommend for this
                      role. Find out more about Vangst training{' '}
                      <ClickyLink
                        href={Routes.LEARN}
                        as="text"
                        className="underline"
                      >
                        here.
                      </ClickyLink>
                    </p>
                  </Tooltip>
                  <div className="flow-y-sm -mt-8">
                    {credentials?.length ? (
                      <div className="flow-y-sm">
                        {fieldsCredentials.map((fieldsCredential: any, i) => {
                          return (
                            <FieldsCredentialsJob
                              credentials={credentials as CredentialFragment[]}
                              defaultValue={fieldsCredential}
                              index={i}
                              key={
                                fieldsCredential.id?.length
                                  ? fieldsCredential.id
                                  : `recommendedCredentials[${i}]`
                              }
                              remove={() => removeCredential(i)}
                            />
                          )
                        })}
                        <ClickyLink
                          as="icon-text"
                          className="link-orange inline-flex items-center text-sm underline"
                          icon={MdAddCircle}
                          onClick={() =>
                            fieldsCredentials?.length === 0
                              ? // @ts-ignore
                                appendCredential({ id: '', credentialId: '' })
                              : // @ts-ignore
                                appendCredential({ id: '' })
                          }
                        >
                          <span className="underline">
                            List Available Trainings
                          </span>
                        </ClickyLink>
                      </div>
                    ) : (
                      <>
                        <span className="text-sm">
                          Looks like you haven't created a training course yet!
                        </span>
                        <span className="text-sm">
                          Help great candidates stand out by adding training
                          courses to this application process.{' '}
                          <ClickyLink
                            onClick={() => setShowServicesModal(true)}
                            className="link-orange underline"
                          >
                            Contact us{' '}
                          </ClickyLink>{' '}
                          to learn more about using training courses.
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </fieldset>

            <fieldset>
              <section>
                <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
                  Salary & Hours
                </h2>
                <div className="mood-grey-lightest mt-4 flex w-full flex-col gap-8 p-4">
                  <FieldsJobSalaryRange
                    defaultValue={jobPostingInput?.salaryRange}
                    errors={errors?.salaryRange}
                    name="salaryRange"
                    unpublishedJobOrder={
                      watchedAtsStatus == JobPostingAtsStatusEnum.Pending
                    }
                    payPeriodRequired={
                      !isEmptyOrNil(watchedSalary?.minValue) ||
                      !isEmptyOrNil(watchedSalary?.maxValue)
                    }
                    register={register}
                  />

                  <div>
                    <div className="grid items-center gap-4 sm:grid-cols-3">
                      <ChooseEmploymentTypes
                        required={shouldValidate('employmentTypes')}
                        error={errors?.employmentTypes?.message}
                        label="Employment Types"
                        reference={employmentTypesRef}
                        restProps={employmentTypesProps}
                        name="employmentTypes"
                      />
                      <Input
                        error={errors?.startDate?.message}
                        label="Start Date"
                        reference={startDateRef}
                        type="date"
                        {...startDateProps}
                        required={shouldValidate('startDate')}
                      />
                      <Input
                        error={errors?.endDate?.message}
                        label="End Date (estimated)"
                        reference={endDateRef}
                        type="date"
                        {...endDateProps}
                      />
                      <Input
                        error={errors?.shift?.message}
                        label="Shift"
                        type="text"
                        {...register('shift')}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </fieldset>

            <fieldset>
              <section>
                <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
                  Job Description
                </h2>
                <div className="mood-grey-lightest flex w-full flex-col gap-8 p-4">
                  <Controller
                    render={({ field }) => (
                      <Editor
                        error={errors?.description?.message}
                        label="Job Description *"
                        placeholder="Share a brief description of this job. Think of it like a sales pitch about this role and your company."
                        {...field}
                      />
                    )}
                    name="description"
                    control={control}
                    rules={{
                      validate: shouldValidate('description')
                        ? {
                            maxLength: validations.slateMaxLength,
                            required: validations.slateRequired,
                          }
                        : {
                            maxLength: validations.slateMaxLength,
                          },
                    }}
                  />

                  <Controller
                    render={({ field }) => (
                      <Editor
                        error={errors?.responsibilities?.message}
                        label="Responsibilities *"
                        placeholder="Describe what employees will need to do in a typical day on the job. We recommend using a list to make it easier to read."
                        {...field}
                      />
                    )}
                    name="responsibilities"
                    control={control}
                    rules={{
                      validate: shouldValidate('responsibilities')
                        ? {
                            maxLength: validations.slateMaxLength,
                            required: validations.slateRequired,
                          }
                        : {
                            maxLength: validations.slateMaxLength,
                          },
                    }}
                  />
                  <Controller
                    render={({ field }) => (
                      <Editor
                        error={errors?.experience?.message}
                        label="Experience"
                        placeholder="What skills and/or prior experience helps someone succeed in this role?"
                        {...field}
                      />
                    )}
                    name="experience"
                    control={control}
                    rules={{
                      validate: shouldValidate('experience')
                        ? {
                            maxLength: validations.slateMaxLength,
                            required: validations.slateRequired,
                          }
                        : {
                            maxLength: validations.slateMaxLength,
                          },
                    }}
                  />
                  <Controller
                    render={({ field }) => (
                      <Editor
                        error={errors?.physicalRequirements?.message}
                        label="Physical Requirements"
                        placeholder="What physical requirements are needed to perform this role? Example: 'Employee is frequently required to lift up to 25 pounds' or 'Employees frequently climb ladders up to 6 ft'."
                        {...field}
                      />
                    )}
                    name="physicalRequirements"
                    control={control}
                    rules={{
                      validate: {
                        maxLength: validations.slateMaxLength,
                      },
                    }}
                  />
                  <Controller
                    render={({ field }) => (
                      <Editor
                        error={errors?.education?.message}
                        label="Education"
                        placeholder="If there are educational requirements to perform this role, describe them here."
                        {...field}
                      />
                    )}
                    name="education"
                    control={control}
                    rules={{
                      validate: {
                        maxLength: validations.slateMaxLength,
                      },
                    }}
                  />
                  <Controller
                    render={({ field }) => (
                      <Editor
                        error={errors?.workingEnvironment?.message}
                        label="Working Environment"
                        placeholder="Describe the working environment for this specific role. For example: 'Work occurs outdoors or in a space similar to a greenhouse subject to temperature changes'."
                        {...field}
                      />
                    )}
                    name="workingEnvironment"
                    control={control}
                    rules={{
                      validate: {
                        maxLength: validations.slateMaxLength,
                      },
                    }}
                  />
                </div>
              </section>
            </fieldset>
            {showDateError && (
              <AlertText
                className="mt-4 text-sm italic text-red"
                icon={MdError}
              >
                Make sure the end date is after the start date.
              </AlertText>
            )}
            {showAssociatedClientError && (
              <AlertText
                className="mt-4 text-sm italic text-red"
                icon={MdError}
              >
                Job orders must have an associated company selected.
              </AlertText>
            )}
            {isJobOrder && (
              <div className="-mb-4">
                <ClickyBtn
                  as="text-icon"
                  className="btn mood-orange"
                  disabled={saving}
                  type="submit"
                >
                  {saving ? 'Saving' : 'Save Job Posting'}
                </ClickyBtn>
              </div>
            )}
          </div>
        </div>
        {!isDialog && (
          <aside className="contain-xy mood-grey-lightest">
            <ul className="flex flex-col sm:pl-8 md:sticky md:top-24">
              {!isJobOrder && (
                <li className="mb-6 space-y-6 border-b pb-6">
                  <span className="text-lg font-700">
                    Vangst's Service Offerings
                  </span>
                  <span className="mb-4 block text-sm">
                    Did you know that Vangst offers base, premium, and expert
                    services? Our service levels help reduce your team's effort
                    when locating and hiring great talent.
                  </span>
                  <ClickyLink
                    onClick={() => setShowServicesModal(true)}
                    className="link-orange"
                  >
                    Learn More About Our Premium Services
                  </ClickyLink>
                </li>
              )}
              <li>
                <ClickyBtn
                  as="text-icon"
                  className="btn mood-orange"
                  disabled={saving}
                  type="submit"
                >
                  {saving ? 'Saving' : 'Save Job Posting'}
                </ClickyBtn>
              </li>
              <li className="mt-4">
                <ClickyBtn
                  as="text-icon"
                  className="text-sm font-700"
                  disabled={saving}
                  type="reset"
                >
                  Cancel Changes
                </ClickyBtn>
              </li>
              {jobId &&
                jobPosting?.permissions?.destroy &&
                jobPosting?.isPublished &&
                !viewer?.permissions?.internalUser && (
                  <li className="mt-8 border-t pt-8">
                    <DeleteJobPosting id={jobId} />
                  </li>
                )}
              {!isEmptyOrNil(errors) && (
                <li>
                  <AlertText
                    aria-live="assertive"
                    className="mt-8 text-sm text-red"
                    icon={MdError}
                  >
                    <span>A validation error occurred.</span>
                  </AlertText>
                </li>
              )}
              {error != null && (
                <li className="mt-8">
                  <QueryErrorFallback kind="block" error={error} />
                </li>
              )}
            </ul>
          </aside>
        )}
      </form>
    </FormProvider>
  )
}

export default memo(JobPostingEditForm)
