import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import AlertText from '../feedback/AlertText'
import Label, { LabelPropsType } from './Label'

export type InputType = React.InputHTMLAttributes<HTMLInputElement> &
  LabelPropsType & {
    readonly classNameLabel?: string
    readonly count?: string
    readonly error?: string
    readonly hint?: string
    readonly name: string
    readonly reference?: React.Ref<HTMLInputElement>
  }

/**
 * The `label > text + input + error/hint` elements.
 *
 * @see [MDN input](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input)
 *
 * @example
 * <Input label="First Name" name="firstName" required />
 * <Input
 *   className="max-w-prose border-dashed border-magenta"
 *   classNameLabel="ml-24"
 *   error="Seems suspect."
 *   hint="Passwords must have lots of characters."
 *   label="Password"
 *   labelSubtext="(entry is weak)"
 *   name="password"
 *   placeholder="Custom placeholder"
 *   type="password"
 *   required
 * />
 */
const Input = forwardRef<HTMLInputElement, InputType>(
  function Input(props, ref) {
    const {
      className,
      classNameLabel,
      count,
      error,
      hideLabel,
      hint,
      label,
      labelSubtext,
      name,
      placeholder,
      reference,
      required,
      ...rest
    } = props
    const id = props.id || props.name
    return (
      <label
        className={clsx('flex w-full flex-col gap-2', classNameLabel)}
        htmlFor={name}
      >
        <Label
          hideLabel={hideLabel}
          label={label}
          labelSubtext={labelSubtext}
          required={required}
        />
        <input
          id={id}
          className={clsx(
            'h-12 w-full py-3 text-sm transition placeholder:text-sm placeholder:italic focus:border-orange focus:outline-none focus:ring-orange',
            '!text-grey',
            error != null && 'border-2 border-red',
            className,
          )}
          name={name}
          placeholder={placeholder || `Enter ${label}`}
          ref={ref || reference}
          type="text"
          {...rest}
        />
        <span className="ml-auto">
          {count != null && (
            <span className="flex justify-end text-sm italic">{count}</span>
          )}
          {(error != null || hint != null) && (
            <AlertText
              className={clsx('text-sm italic', error != null && 'text-red')}
              icon={MdErrorOutline}
              role={error ? 'alert' : 'note'}
            >
              {error || hint}
            </AlertText>
          )}
        </span>
      </label>
    )
  },
)

export default Input
