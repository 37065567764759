import clsx from '@vangst/lib/clsx'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { useRouter } from 'next/router'
import { HTMLAttributes, memo } from 'react'
import { MdComment } from 'react-icons/md'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import DialogMessaging from './feedback/DialogMessaging'
import useMessages from './useMessages'

const MessagesWidgetToggle = (
  props: { isWidget?: boolean } & HTMLAttributes<HTMLDivElement>,
) => {
  const { isWidget = false } = props
  const { asPath } = useRouter()
  const { viewer } = useViewer()

  const {
    exitWidget,
    widgetView,
    handleToggleWidget,
    messagingWidgetIsVisible,
  } = useMessages({
    isWidget,
  })

  if (
    viewer == null ||
    viewer.permissions.sendMessage == null ||
    asPath.includes('message')
  ) {
    return null
  }

  return (
    <>
      {!messagingWidgetIsVisible && (
        <ClickyBtn
          onClick={() => handleToggleWidget()}
          className={clsx(
            'flex items-center justify-center',
            'h-12 w-12',
            'rounded-sm border bg-white/90 text-orange',
          )}
        >
          <MdComment className="h-8 w-8" />
        </ClickyBtn>
      )}
      {messagingWidgetIsVisible && (
        <DialogMessaging
          handleCancel={() => exitWidget()}
          widgetView={widgetView}
        />
      )}
    </>
  )
}

export default memo(MessagesWidgetToggle)
