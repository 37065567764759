import { Combobox } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import Avatar from '../../../components/views/Avatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPosting?: JobPostingComputed
  readonly title: string
  readonly uri: string
}

function JobPostingMenuItem(props: PropsType) {
  const { className, jobPosting, title, uri, ...rest } = props
  const info = jobPosting
    ? `${jobPosting.client.name} ${jobPosting.postedAtWords}`
    : title
  return (
    <Combobox.Option
      as="div"
      key={jobPosting?.id ?? uri}
      value={jobPosting?.slug ?? uri}
      className={clsx(
        'group/comboboxoption flow-x-xs mood-white cursor-pointer items-center p-2',
        'aria-selected:mood-green hocus:mood-green aria-selected:font-600',
        className,
      )}
      title={info}
      {...rest}
    >
      <span className="text-orange">
        <MdCheckBox
          aria-hidden="true"
          className="hidden group-aria-selected/comboboxoption:inline"
        />
        <MdCheckBoxOutlineBlank
          aria-hidden="true"
          className="group-aria-selected/comboboxoption:hidden"
        />
      </span>
      <Avatar
        alt={jobPosting?.client.initials ?? '...'}
        className="h-8 w-8"
        src={jobPosting?.client.avatarUrl}
      />
      <span>{title}</span>
    </Combobox.Option>
  )
}

export default JobPostingMenuItem
