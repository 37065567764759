import clsx from '@vangst/lib/clsx'
import { usePageInput } from '@vangst/services/oogst/page'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { memo, useState } from 'react'
import { MdEdit } from 'react-icons/md'
import Busy from '../../../components/feedback/Busy'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyLink from '../../../components/navigation/ClickyLink'
import PageEditForm from '../PageEditForm'

function PageEditDialog() {
  const [showDialog, setShowDialog] = useState(false)
  const show = () => setShowDialog(true)
  const hide = () => setShowDialog(false)
  const { viewer } = useViewer()
  const authorized = viewer?.permissions.imposter

  const { data, isLoading } = usePageInput({}, { enabled: authorized })

  if (!authorized) return null
  return (
    <>
      <ClickyLink
        as="icon"
        icon={MdEdit}
        aria-expanded={showDialog}
        className={clsx(
          'relative flex items-center justify-center',
          'h-12 w-12',
          'rounded-sm border bg-white/90 text-orange',
        )}
        id="admin-page-edit-dialog"
        onClick={show}
        title="Edit Page"
      />

      {showDialog && (
        <DialogModal onClose={hide} open={showDialog}>
          <DialogModal.Panel icon={MdEdit} title="Edit Page Info.">
            <DialogModal.Description className="mb-8 text-sm">
              <strong>slug: </strong>
              {globalThis.location?.href}
            </DialogModal.Description>
            {isLoading && <Busy />}
            {!isLoading && <PageEditForm close={hide} page={data?.getPage} />}
          </DialogModal.Panel>
        </DialogModal>
      )}
    </>
  )
}

export default memo(PageEditDialog)
