import clsx from '@vangst/lib/clsx'
import { MemberComputed } from '@vangst/services/oogst/member/useMember'
import { MdOutlineMessage } from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import useMessages from '../../messaging/useMessages'

type PropsType = React.HTMLAttributes<HTMLAnchorElement | HTMLButtonElement> & {
  readonly isChat?: boolean
  readonly member?: MemberComputed
  readonly title: string
  readonly uri: string
}

function MemberAvatar(props: PropsType) {
  const { isChat, member, title, uri, ...rest } = props
  const loading = member == null ? '•••' : undefined

  const { handleMessageUser } = useMessages({ isWidget: true })

  return (
    <ClickyLink
      className={clsx(
        'relative flex h-12 w-12 ease-bounce hocus:scale-105',
        member == null && 'scale-90',
        isChat && 'group',
        props.className,
      )}
      onClick={
        isChat && member != null
          ? () => handleMessageUser(member, 'show')
          : undefined
      }
      href={isChat ? undefined : uri}
      title={title}
      {...rest}
    >
      {isChat && (
        <span className="absolute left-0 top-0 flex h-12 w-12 items-center justify-center rounded-full bg-black/20 text-white opacity-0 group-hover:opacity-80">
          <MdOutlineMessage />
        </span>
      )}
      <Avatar
        alt={loading ?? member?.initials ?? ''}
        className={clsx(
          'mood-grey',
          member?.permissions?.hasRecruiterLabel && 'border-2 border-orange',
        )}
        src={member?.avatarUrl || undefined}
      />
    </ClickyLink>
  )
}

export default MemberAvatar
