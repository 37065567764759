import clsx from '@vangst/lib/clsx'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import titleize from '@vangst/lib/titleize'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { MdBadge, MdCheckCircle, MdHomeWork, MdMoreHoriz } from 'react-icons/md'
import Busy from '../../../components/feedback/Busy'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import JobPostingAvatar from './JobPostingAvatar'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPosting?: JobPostingComputed
  readonly title: string
  readonly uri: string
}

function JobPostingCard(props: PropsType) {
  const { className, jobPosting, title, uri, ...rest } = props
  const { viewer } = useViewer()
  const loading = jobPosting == null ? <MdMoreHoriz /> : undefined
  return (
    <div
      className={clsx(
        'boxshadow boxlink mood-white relative flex h-80 w-full flex-col gap-4 rounded-md pt-4',
        className,
      )}
      {...rest}
    >
      {/* Header */}
      <div className="flex h-18 items-center gap-4 px-4">
        <JobPostingAvatar jobPosting={jobPosting} title={title} />
        <div className="flex flex-col">
          <span className="line-clamp-2 text-sm">
            {loading ? <Busy /> : jobPosting?.client.name ?? ''}
          </span>
          <h2 className="line-clamp-2 font-400">
            <ClickyLink
              href={uri}
              className={clsx(
                'hitarea text-[12px]',
                (uri == null || loading) && 'pointer-events-none',
              )}
            >
              {title}
            </ClickyLink>
          </h2>
          {jobPosting?.permissions?.internalUser && (
            <div className="grid-two-up text-[12px]">
              <span>Level: {jobPosting?.gigsInfo?.level || '—'}</span>
              <span>Status: {titleize(jobPosting?.atsStatus ?? '—')}</span>
            </div>
          )}
        </div>
      </div>

      {/* Body */}
      <div className="flow-y-xs -mt-1 px-4">
        <div className="flow-y-xs">
          <span className="text-sm">
            {loading ??
              (jobPosting?.isRemote ? 'Remote' : jobPosting?.citystate) ??
              ''}
          </span>
          {jobPosting?.isPublished === false ? (
            <span className="mood-green flex max-w-fit items-center rounded px-2 text-[12px]">
              Unpublished
            </span>
          ) : (
            <span className="flex items-center text-[12px]">
              {loading ? (
                <MdMoreHoriz />
              ) : jobPosting?.postedAtWords ? (
                `Posted ${jobPosting?.postedAtWords}`
              ) : (
                ''
              )}
            </span>
          )}
        </div>
      </div>

      {/* Badges */}
      <ul className="flow-y-xs mt-auto px-4 text-sm capitalize">
        <li className="flex items-center gap-2 truncate">
          <MdBadge
            className={clsx(
              jobPosting?.requiredLicenses != null &&
                jobPosting.requiredLicenses.length > 0
                ? 'text-orange'
                : 'text-grey-mid',
            )}
          />
          <span>
            {jobPosting?.requiredLicenses == null ? (
              <MdMoreHoriz />
            ) : jobPosting.requiredLicenses.length > 0 ? (
              'Badge Required'
            ) : (
              'Badge Not Required'
            )}
          </span>
        </li>

        {!isEmptyOrNil(jobPosting?.employmentTypesFormatted) && (
          <li className="flex items-center gap-2 overflow-x-hidden">
            <MdHomeWork className="text-orange" />
            <span className="truncate">
              {jobPosting?.employmentTypesFormatted}
            </span>
          </li>
        )}
        {jobPosting?.showReceived && (
          <li className="flex items-center gap-2 truncate font-600 text-green">
            <MdCheckCircle className="text-green" />
            <span>Application Received</span>
          </li>
        )}
      </ul>

      {/* Footer */}
      {viewer?.permissions?.internalUser ? (
        <ClickyBtn
          className="mood-grey-light relative z-10 mt-auto h-18 w-full transition"
          href={jobPosting?.routes?.jobsApplications}
        >
          <span className="grid w-full grid-cols-[3fr_1fr_3fr]">
            <dl className="text-center">
              <dt className="text-[12px] uppercase">New Applicants</dt>
              <dd className="mt-2 text-md">
                {jobPosting?.countJobApplicationsNew}
              </dd>
            </dl>
            <div className="m-auto h-6 border-l-[1px]" role="presentation" />
            <dl className="text-center">
              <dt className="text-[12px] uppercase">Total Applicants</dt>
              <dd className="mt-2 text-md">
                {jobPosting?.countJobApplicationsTotal}
              </dd>
            </dl>
          </span>
        </ClickyBtn>
      ) : (
        <div className="mt-auto flex h-16 px-4">
          {loading ? (
            <MdMoreHoriz />
          ) : (
            <ClickyLink
              as="text-icon"
              className={clsx(
                'hitarea link-orange underline',
                (uri == null || loading) && 'pointer-events-none',
              )}
              disabled={uri == null || loading}
              href={uri}
            >
              View Detail
            </ClickyLink>
          )}
        </div>
      )}
    </div>
  )
}

export default JobPostingCard
