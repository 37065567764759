import clsx from '@vangst/lib/clsx'
import { fromISO } from '@vangst/lib/formatDates'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import {
  MemberComputed,
  MemberFragment,
  MemberOptions,
  MemberVars,
} from '@vangst/services/oogst/member/useMember'
import { PlacementStatusEnum } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import {
  MdDescription,
  MdMoreHoriz,
  MdOutlineExpandMore,
  MdOutlineMessage,
} from 'react-icons/md'
import SocialLinkedIn from '../../../components/assets/social/SocialLinkedIn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import {
  emptyText,
  TableRowSummary,
  TableSummaryDetailList,
} from '../../application/TableRow'
import useParams from '../../application/useParams'
import useMessages from '../../messaging/useMessages'
import MemberActionsMenu from '../navigation/MemberActionsMenu'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPostingId?: string
  readonly isFeatured?: boolean
  readonly member?: MemberComputed
  readonly options?: MemberOptions
  readonly uri: string
  readonly variables: MemberVars
}

function MemberTableRowSummary(props: PropsType) {
  const { isFeatured, jobPostingId, member, options, uri, variables } = props
  const loading = member == null ? <MdMoreHoriz /> : undefined

  const { toggleParams } = useParams()
  const { handleMessageUser } = useMessages({
    isWidget: true,
  })

  const applications = member?.candidate?.jobApplications
  const placements = member?.candidate?.placements
  const oneApp = applications?.length === 1 ? applications[0] : null
  const multipleApps = applications?.length && applications?.length > 1

  const highlight = placements?.find(
    (p) => p.status === PlacementStatusEnum.Approved,
  )

  return (
    <TableRowSummary
      toggleParams={() =>
        toggleParams({ key: 'open', value: member?.username })
      }
      className={clsx(highlight && 'bg-green/20')}
    >
      <span className="flex gap-2">
        <TableSummaryDetailList title="Name">
          <span className="flow-x-xs w-40 items-center">
            <Avatar
              alt={'···' ?? member?.initials ?? ''}
              className="mood-grey h-4 w-4 shrink-0"
              src={member?.avatarUrl || undefined}
            />
            <ClickyLink href={uri} className="link-orange truncate">
              {member?.fullName}
            </ClickyLink>
          </span>
        </TableSummaryDetailList>
        {oneApp && (
          <>
            <TableSummaryDetailList title="Company">
              <span className="flex w-32 items-center truncate">
                <ClickyLink
                  href={Routes.COMPANIES_DETAIL.replace(
                    ':displayname',
                    oneApp?.jobPosting?.client?.displayname || 'name',
                  )}
                  className="link-orange truncate"
                >
                  {oneApp?.jobPosting?.client?.name}
                </ClickyLink>
              </span>
            </TableSummaryDetailList>
            <TableSummaryDetailList title="Job Posting">
              <span className="flex w-32 items-center truncate">
                <ClickyLink
                  href={oneApp?.jobPosting?.slug}
                  className="link-orange truncate"
                >
                  {oneApp?.jobPosting?.title}
                </ClickyLink>
              </span>
            </TableSummaryDetailList>
            {placements?.length &&
              placements.length === 1 &&
              placements[0]?.startDate && (
                <TableSummaryDetailList title="Start Date">
                  <span className="flex w-32 items-center truncate">
                    {fromISO(placements[0]?.startDate)?.MMddyy}
                  </span>
                </TableSummaryDetailList>
              )}
          </>
        )}
        {multipleApps && (
          <TableSummaryDetailList title="Multiple Applications">
            <span className="flex w-36 items-center truncate">
              (Expand row to view)
            </span>
          </TableSummaryDetailList>
        )}
        <TableSummaryDetailList title="Location">
          <span className="flex w-32 items-center truncate">
            {loading
              ? loading
              : !member?.citystate
              ? emptyText
              : member?.citystate}
          </span>
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Phone">
          <span className="flex w-24 items-center">
            {loading
              ? loading
              : !member?.phoneNumber
              ? emptyText
              : member?.phoneNumber}
          </span>
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Resume">
          <span className="flex w-18 items-center">
            {loading ? (
              loading
            ) : !isEmptyOrNil(member?.candidate?.resumeUrl) ? (
              <ClickyLink
                className="link-orange flex items-center justify-center"
                href={member?.candidate?.resumeUrl}
              >
                <MdDescription className="h-4 w-4" />
              </ClickyLink>
            ) : (
              emptyText
            )}
          </span>
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Linked In">
          <span className="flex w-18 items-center">
            {loading ? (
              loading
            ) : !isEmptyOrNil(member?.social?.linkedin) ? (
              <ClickyLink
                className="link-orange flex items-center justify-center"
                href={member?.social?.linkedin}
              >
                <SocialLinkedIn className="h-4 w-4" />
              </ClickyLink>
            ) : (
              emptyText
            )}
          </span>
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Licenses">
          <span className="flex w-18 items-center">
            {loading ? (
              loading
            ) : (
              <span className="text-sm">
                {member?.candidate?.candidateLicenses?.length || 0}
              </span>
            )}
          </span>
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Activities">
          <span className="flex w-18 items-center">
            {loading ? (
              loading
            ) : (
              <span className="text-sm">
                {member?.activities?.pageInfo?.totalCount || 0}
              </span>
            )}
          </span>
        </TableSummaryDetailList>
      </span>
      <div className="flow-x-xs ml-auto flex items-center">
        {!loading && (
          <ClickyLink
            as="icon"
            icon={MdOutlineMessage}
            className={
              'flex h-4 w-4 items-center justify-center text-orange hocus:border-orange hocus:bg-orange hocus:text-white'
            }
            onClick={() => handleMessageUser(member as MemberFragment)}
            title={`Send message to ${member?.fullName}`}
          >
            <MdOutlineMessage />
          </ClickyLink>
        )}
        {member && !isFeatured && (
          <div data-id="three-dot-menu">
            <MemberActionsMenu
              jobPostingId={jobPostingId}
              variables={variables}
              options={options}
              url={uri}
            />
          </div>
        )}
        <MdOutlineExpandMore className="transition-transform group-open/details:rotate-180" />
      </div>
    </TableRowSummary>
  )
}

export default MemberTableRowSummary
