import clsx from '@vangst/lib/clsx'
import { SignUpContextEnum } from '@vangst/services/oogst/types'
import Routes from '@vangst/services/routes'
import { setSessionRedirect, StorageKeys } from '@vangst/services/storage'
import { useEffect, useState } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { DialogModal } from '../../../components/feedback/DialogModal'
import ClickyLink from '../../../components/navigation/ClickyLink'
import SignInForm from '../SignInForm'
import SignUpForm from '../SignUpForm'
import SignUpFormCompany from '../SignUpFormCompany'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  contextMember?: SignUpContextEnum
  contextCompany?: SignUpContextEnum
  handleClose: () => void
  redirect?: Routes
  skipRedirect?: boolean
}

export default function AlertDialogSignInOrUp(props: PropsType) {
  const [userRole, setUserRole] = useState<'company' | 'member'>('member')
  const [view, setView] = useState<'signin' | 'signup'>('signin')
  const toggleMember = () => setUserRole('member')
  const toggleCompany = () => setUserRole('company')

  useEffect(() => {
    if (
      globalThis.sessionStorage?.getItem(StorageKeys.REDIRECT) == null &&
      props.redirect != null
    ) {
      setSessionRedirect(props.redirect)
    }
  }, [props.redirect])

  const { children, contextCompany, contextMember, handleClose } = props

  const companyBlurb =
    "Create a free Vangst company account now to get access to the largest network of cannabis pros and the best talent-matching tools in the industry – to help you find the unicorn talent you've been looking for."

  const memberBlurb =
    'Join the largest network of Cannabis professionals. As a member of Vangst, you can find cannabis jobs, build your industry network, and join the conversation with thousands of other cannabis pros.'

  const kids =
    children != null
      ? children
      : userRole === 'member'
      ? memberBlurb
      : companyBlurb

  const SignIn = () => {
    return (
      <div className="bg-grey-lightest p-4">
        <SignInForm as="dialog" handleClose={handleClose} />
      </div>
    )
  }

  const SignUp = () => {
    return (
      <div className="bg-grey-lightest p-4">
        {userRole === 'member' ? (
          <SignUpForm
            as="dialog"
            handleClose={handleClose}
            from={
              contextMember != null
                ? contextMember
                : SignUpContextEnum?.MemberSignUpForm
            }
          />
        ) : (
          <SignUpFormCompany
            handleClose={handleClose}
            from={
              contextCompany != null
                ? contextCompany
                : SignUpContextEnum.CompanySignUpForm
            }
          />
        )}
      </div>
    )
  }

  return (
    <DialogModal className="!bg-orange-light/90" onClose={handleClose} open>
      <DialogModal.Panel className="relative">
        <header className="flow-y-sm w-full pb-4 sm:px-0">
          <span className="flex items-end justify-between">
            <span className="flow-x-sm w-full">
              <DialogModal.Title>
                <button
                  className={clsx(
                    'px-4 py-2 text-orange-medium outline-none heading-sm focus-visible:ring-0 focus-visible:ring-transparent',
                    view === 'signup' && 'bg-orange-medium text-white',
                  )}
                  onClick={() => setView('signup')}
                >
                  Sign Up
                </button>
              </DialogModal.Title>
              <DialogModal.Title>
                <button
                  className={clsx(
                    'px-4 py-2 text-orange-medium heading-sm',
                    view === 'signin' &&
                      'bg-orange-medium !text-white outline-none focus-visible:ring-0 focus-visible:ring-transparent',
                  )}
                  onClick={() => setView('signin')}
                >
                  Sign In
                </button>
              </DialogModal.Title>
            </span>
            {view === 'signup' && (
              <button
                className="ml-auto shrink-0 text-[12px] text-orange-medium underline"
                onClick={userRole === 'member' ? toggleCompany : toggleMember}
              >
                Create a {userRole === 'member' ? 'company' : 'member'} account
              </button>
            )}
          </span>
          <DialogModal.Description className="text-xs font-600">
            {kids}
          </DialogModal.Description>
        </header>

        <div className="grid w-full gap-8 p-0">
          {view === 'signin' ? <SignIn /> : <SignUp />}
        </div>

        <ClickyLink
          as="icon"
          icon={MdOutlineCancel}
          className="link-grey absolute right-4 top-4 z-10"
          onClick={handleClose}
        >
          Close
        </ClickyLink>
      </DialogModal.Panel>
    </DialogModal>
  )
}
