import { GoogleLogin } from '@react-oauth/google'
import { useQueryClient } from '@tanstack/react-query'
import clsx from '@vangst/lib/clsx'
import useLogin from '@vangst/services/oogst/viewer/useLogin'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdError } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import Input from '../../components/forms/Input'
import ClickyBtn from '../../components/navigation/ClickyBtn'
import QueryErrorBoundary from '../application/feedback/QueryErrorBoundary'
import QueryErrorFallback from '../application/feedback/QueryErrorFallback'
import validations from '../application/validations'
import { GoForgotPassword } from './navigation/GoDoThings'

type PropsType = {
  as?: 'page' | 'dialog'
  handleClose?: () => void
  trackingId?: string
}
function SignInForm(props: PropsType) {
  const { as = 'page', handleClose, trackingId } = props
  const [oauthError, setOauthError] = useState(false)

  const defaultValues = { email: '', password: '' }
  type FormInput = typeof defaultValues

  const queryClient = useQueryClient()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues })

  const { mutate, isLoading, error: serverError } = useLogin(queryClient)

  const submit = React.useCallback(
    (fd: FormInput) => {
      mutate(
        { input: { ...fd } },
        { onSuccess: handleClose != null ? handleClose : undefined },
      )
    },
    [mutate, handleClose],
  )

  const send = React.useMemo(() => handleSubmit(submit), [handleSubmit, submit])

  return (
    <div>
      <div className="mb-6">
        <QueryErrorBoundary kind="status">
          <GoogleLogin
            onSuccess={(credentialResponse: any) => {
              const obj = {
                token: credentialResponse.credential,
              }
              mutate({ input: obj })
            }}
            onError={() => {
              setOauthError(true)
            }}
            theme="filled_blue"
            context="signin"
            width={200}
          />
          {oauthError && (
            <AlertText
              className="mt-4 text-center text-sm text-red"
              icon={MdError}
            >
              <span>There was an error siging in with Google.</span>
            </AlertText>
          )}
        </QueryErrorBoundary>
      </div>
      <form id="sign-in-form" onSubmit={send} className="flow-y-sm">
        <Input
          error={errors?.email?.message}
          label="email"
          required
          {...register('email', validations.email)}
        />
        <Input
          error={errors?.password?.message}
          label="password"
          type="password"
          required
          {...register('password', validations.password)}
        />
        {as === 'dialog' && <GoForgotPassword />}
        <div className={clsx(as === 'page' ? 'w-full' : 'flex justify-end')}>
          <ClickyBtn
            as="text-icon"
            className="mood-orange-medium-inverted"
            disabled={isLoading}
            id={trackingId != null ? trackingId : 'submit-sign-in-form'}
            type="submit"
          >
            Sign In
          </ClickyBtn>
        </div>
        {serverError != null && <QueryErrorFallback error={serverError} />}
      </form>
    </div>
  )
}

export default React.memo(SignInForm)
