import titleize from '@vangst/lib/titleize'
import {
  CompanyComputed,
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import {
  MdMoreHoriz,
  MdOutlineExpandMore,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import { TableSummaryDetailList } from '../../application/TableRow'
import useParams from '../../application/useParams'
import CompanyActionsMenu from '../navigation/CompanyActionsMenu'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly company?: CompanyComputed
  readonly options?: CompanyOptions
  readonly uri: string
  readonly variables: CompanyVars
}
function CompanyTableRowSummary(props: PropsType) {
  const { company, options, uri, variables } = props
  const loading = company == null ? <MdMoreHoriz /> : undefined
  const { toggleParams } = useParams()

  return (
    <summary
      className="flex list-none gap-4 p-2"
      onClick={() => toggleParams({ key: 'open', value: company?.displayname })}
    >
      <div className="flow-x-sm w-full">
        <TableSummaryDetailList title="Company">
          <span className="flow-x-xs">
            <Avatar
              alt={'···' ?? company?.initials ?? ''}
              className="mood-grey h-4 w-4 shrink-0"
              src={company?.avatarUrl || undefined}
            />
            <ClickyLink
              href={uri}
              className="link-orange truncate text-[12px] font-600"
            >
              {loading ?? titleize(company?.displayname ?? '')}
            </ClickyLink>
          </span>
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Published">
          <span className="flow-x-xs">
            <span className="w-4">
              {loading && <MdMoreHoriz className="h-4 w-4" />}
              {company?.visibleOnPlatform === false && (
                <span
                  title="Unpublished Company"
                  className="flex h-4 w-4 items-center justify-center rounded-full bg-red/50"
                >
                  <MdOutlineVisibilityOff className="h-3 w-3" />
                </span>
              )}
              {company?.visibleOnPlatform === true && (
                <span
                  title="Published Company"
                  className="mood-green-tint flex h-4 w-4 items-center justify-center rounded-full"
                >
                  <MdOutlineVisibility className="h-3 w-3" />
                </span>
              )}
            </span>
            <span>
              {loading || company?.visibleOnPlatform === false ? 'No' : 'Yes'}
            </span>
          </span>
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Location" className="w-32">
          {loading ?? company?.citystate}
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Size" className="w-40">
          {loading ?? company?.companySizeChunk?.label}
        </TableSummaryDetailList>
        <TableSummaryDetailList title="New Postings" className="w-32">
          {loading ?? company?.countRecentPositions}
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Total Postings" className="w-32">
          {loading ?? (
            <span className="flow-x-xs">
              {company?.countAvailablePositions}
              <ClickyLink
                className="link-orange"
                href={company?.routes?.jobsIndex}
              >
                View All
              </ClickyLink>
            </span>
          )}
        </TableSummaryDetailList>
        <TableSummaryDetailList title="Trainings" className="w-32">
          {loading ?? (
            <span className="flow-x-xs">
              {company?.trainings?.pageInfo?.totalCount}
              <ClickyLink
                className="link-orange"
                href={company?.routes?.trainingsIndex}
              >
                View All
              </ClickyLink>
            </span>
          )}
        </TableSummaryDetailList>
        <div className="flow-x-xs ml-auto items-center">
          {loading ?? (
            <CompanyActionsMenu variables={variables} options={options} />
          )}
          <MdOutlineExpandMore className="ml-auto transition-transform group-open/details:rotate-180" />
        </div>
      </div>
    </summary>
  )
}

export default CompanyTableRowSummary
