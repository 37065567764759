import clsx from '@vangst/lib/clsx'
import leadingZero from '@vangst/lib/leadingZero'
import {
  CompanyComputed,
  CompanyOptions,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import { MdMoreHoriz } from 'react-icons/md'
import ClickyLink from '../../../components/navigation/ClickyLink'
import Avatar from '../../../components/views/Avatar'
import CompanyActionsMenu from '../navigation/CompanyActionsMenu'
import UnpublishedTag from '../UnpublishedTag'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly options?: CompanyOptions
  readonly showStats?: boolean
  readonly title: string
  readonly uri: string
  readonly variables: CompanyVars
}

function CompanyCard(props: PropsType) {
  const {
    className,
    company,
    showStats,
    title,
    uri,
    variables,
    options,
    ...rest
  } = props
  const loading = company == null ? <MdMoreHoriz /> : undefined

  return (
    <div
      className={clsx(
        'boxshadow boxlink mood-white relative flex h-80 w-full flex-col gap-4 rounded-md pt-4',
        className,
      )}
      {...rest}
    >
      {company && (
        <div className="absolute right-2 top-6 z-30">
          <CompanyActionsMenu variables={variables} options={options} />
        </div>
      )}
      {/* Header */}
      <div className="flex h-18 items-center gap-4 px-4">
        <Avatar
          alt={company?.initials ?? '...'}
          className="mood-grey shrink-0"
          src={company?.avatarUrl || undefined}
        />
        <div className="mr-4 flex flex-col">
          <h2
            className="line-clamp-1 text-sm"
            title={`@${variables?.displayname}`}
          >
            <ClickyLink href={uri} className="hitarea">
              {title}
            </ClickyLink>
          </h2>
          <p className="line-clamp-1 text-[12px] font-400">
            {loading ?? company?.citystate ?? ''}
          </p>
        </div>
      </div>

      {/* Body */}
      <ul className="flow-y-xs h-36 px-4">
        <li className="h-5 text-[12px] font-400">
          {loading ?? company?.companySizeChunk?.label ?? ''}
        </li>
        <li className="relative z-10 h-5 text-[12px] font-400">
          {loading ?? (
              <ClickyLink
                className="link-blue-dark block w-full truncate pr-2 underline"
                href={company?.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {company?.webname}
              </ClickyLink>
            ) ??
            ''}
        </li>
        <li className="line-clamp-5 h-22 text-[12px] font-400">
          <p>{loading ?? company?.companyDescription ?? ''}</p>
        </li>
        {company?.visibleOnPlatform === false && <UnpublishedTag small />}
      </ul>

      {/* Footer */}
      {showStats && (
        <div className="btn mood-grey-light relative z-10 mt-auto h-18 w-full transition">
          <span className="grid w-full grid-cols-[3fr_1fr_3fr]">
            <dl className="text-center">
              <dt className="text-[10px] uppercase">New Postings</dt>
              <dd className="mt-2 flex justify-center text-md">
                {loading ?? leadingZero(company?.countRecentPositions) ?? ''}
              </dd>
            </dl>
            <div className="m-auto h-6 border-l-[1px]" role="presentation" />
            <dl className="text-center">
              <dt className="text-[10px] uppercase">Total Postings</dt>
              <dd className="mt-2 flex justify-center text-md">
                {loading ?? leadingZero(company?.countAvailablePositions) ?? ''}
              </dd>
            </dl>
          </span>
        </div>
      )}
    </div>
  )
}

export default CompanyCard
