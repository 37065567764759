import { Dialog } from '@headlessui/react'
import {
  HubSpotFormIDs,
  HUBSPOT_SUBJECTS,
  SUBJECT_OPTIONS,
} from '@vangst/services/hubspot'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdCheckCircle, MdOutlineCancel } from 'react-icons/md'
import AlertText from '../../../components/feedback/AlertText'
import { DialogModal } from '../../../components/feedback/DialogModal'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import TextareaCounter from '../../../components/forms/TextareaCounter'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyLink from '../../../components/navigation/ClickyLink'
import StateSelect from '../../application/forms/SelectState'
import useHubSpotForm from '../../application/useHubSpotForm'
import validations from '../../application/validations'

type PropsType = {
  readonly description?: string
  readonly defaultMessageValue?: string
  readonly email: string
  readonly phone: string
  readonly firstName: string
  readonly lastName: string
  readonly handleCancel: () => void
  readonly hubspotContactSubject: HUBSPOT_SUBJECTS
  readonly hideDescription?: boolean
  readonly state: string
  readonly title?: string
}

function DialogContact({
  description,
  defaultMessageValue,
  email,
  phone,
  firstName,
  lastName,
  handleCancel,
  hubspotContactSubject,
  hideDescription,
  state,
  title,
}: PropsType) {
  const [success, setSuccess] = useState(false)
  const focusRef = useRef<HTMLButtonElement>(null)
  const { asPath } = useRouter()

  const defaultValues = {
    contact_notes: defaultMessageValue || '',
    firstname: firstName,
    lastname: lastName,
    phone: phone,
    state: state,
    email: email,
    subject: hubspotContactSubject || HUBSPOT_SUBJECTS.OTHER,
  }

  type FormInput = typeof defaultValues

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<FormInput>({ defaultValues })

  const {
    handleSubmitHSForm,
    saving,
    error: hsError,
  } = useHubSpotForm(HubSpotFormIDs.Contact)
  const contact_notes = watch('contact_notes')
  const messageLen = contact_notes?.length ?? 0

  const submit = (fd: FormInput) => {
    const input = {
      submittedAt: Date.now(),
      fields: [
        { name: 'email', value: fd.email },
        { name: 'phone', value: fd.phone },
        { name: 'firstName', value: fd.firstname },
        { name: 'lastName', value: fd.lastname },
        { name: 'state', value: fd.state },
        { name: 'contact_subject', value: fd.subject },
        { name: 'contact_notes', value: fd.contact_notes },
      ],
      context: {
        pageName: asPath,
      },
    }

    handleSubmitHSForm(input)

    if (Object.keys(errors).length === 0 && hsError === false) {
      setSuccess(true)
    }
  }

  return (
    <>
      <DialogModal className="!bg-orange-light/90" onClose={handleCancel} open>
        <DialogModal.Panel className="relative">
          <ClickyLink
            as="icon"
            icon={MdOutlineCancel}
            className="link-grey absolute right-1 top-1 z-10"
            onClick={handleCancel}
          >
            Close
          </ClickyLink>
          <Dialog.Title className="mt-4 text-center heading-sm">
            {title || 'Vangst Expert Support'}
          </Dialog.Title>
          {!hideDescription && (
            <Dialog.Description className="mb-4 text-md">
              {description != null ? (
                <span className="prose">{description}</span>
              ) : (
                <ul className="list-disc">
                  <li>Temporary Staffing</li>
                  <li>Recruitment Services</li>
                  <li>Executive Placement</li>
                  <li>Hosted Training Courses</li>
                </ul>
              )}
            </Dialog.Description>
          )}
          <form
            className="flex w-full flex-col items-center gap-y-2"
            onSubmit={handleSubmit(submit)}
            onReset={handleCancel}
          >
            {!success && (
              <div>
                <Input
                  id={'contact-firstname'}
                  label="first name"
                  required
                  {...register('firstname', validations.generic)}
                />
                <Input
                  id={'contact-lastname'}
                  label="last name"
                  required
                  {...register('lastname', validations.generic)}
                />
                <Input
                  label="email"
                  required
                  {...register('email', validations.email)}
                  id={'contact-email'}
                />
                <Input
                  label="phone"
                  {...register('phone', validations.phone)}
                  id={'contact-phone'}
                />
                <StateSelect
                  label="state"
                  {...register('state', validations.state)}
                  id={'contact-state'}
                />
                <Select
                  label="Subject"
                  {...register('subject', validations.generic)}
                  options={SUBJECT_OPTIONS}
                  id={`contact_subject`}
                />
                <TextareaCounter
                  label="Message"
                  len={messageLen}
                  max={1200}
                  placeholder="Add any additional information you feel may be helpful."
                  defaultValue={defaultValues.contact_notes}
                  {...register('contact_notes', validations.maxLength1200)}
                />
                <hr className=" h-1 w-full text-grey-mid" />
                <div className="flow-y-sm mt-4 sm:flow-x-sm">
                  <ClickyBtn
                    className="mood-orange w-64"
                    disabled={saving}
                    type="submit"
                    ref={focusRef}
                  >
                    Submit Message
                  </ClickyBtn>
                  <ClickyBtn
                    className="mood-grey-light w-64"
                    disabled={saving}
                    type="reset"
                  >
                    Cancel
                  </ClickyBtn>
                </div>
              </div>
            )}

            {success && (
              <div className="flex flex-col items-center justify-center">
                <AlertText
                  className="mb-6 text-center text-md text-green-dark"
                  icon={MdCheckCircle}
                >
                  An account executive will be in touch as soon as possible.
                </AlertText>
                <ClickyBtn
                  className="mood-grey-light w-64"
                  disabled={saving}
                  type="reset"
                >
                  Close
                </ClickyBtn>
              </div>
            )}
          </form>
        </DialogModal.Panel>
      </DialogModal>
    </>
  )
}

export default DialogContact
