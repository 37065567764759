import clsx from 'clsx'
import { IconType } from 'react-icons'
import ClickyLink from '../navigation/ClickyLink'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly classNameIcon?: string
  readonly icon: IconType
  readonly title: string
  readonly to?: string | null
  readonly toLabel?: string
}

/**
 * A common section heading that includes an icon, optional "View More" link
 * and optional `children`.
 *
 * @example
 * <GroupHeader icon={MdWork} title="Jobs & More" />
 * <GroupHeader
 *   classNameIcon="mood-orange"
 *   icon={MdWork}
 *   title="Doors & Bells"
 *   to="#"
 *   toLabel="Read More"
 * />
 */
function GroupHeader(props: PropsType) {
  const {
    children,
    className,
    classNameIcon,
    icon,
    title,
    to,
    toLabel,
    ...rest
  } = props
  const Icon = icon

  return (
    <div className={clsx('items-center lg:flex', className)} {...rest}>
      <div className="min-w-64">
        <h2
          className="flex items-center heading-sm"
          id={title.replace(' ', '-').toLowerCase()}
        >
          <span
            className={clsx(
              'mood-grey mr-4 hidden h-12 w-12 items-center justify-center rounded-full sm:flex',
              classNameIcon,
            )}
            role="presentation"
          >
            <Icon size="1.25em" />
          </span>
          <span>{title}</span>
        </h2>
        {to != null && (
          <ClickyLink
            className="link-orange relative text-sm capitalize sm:ml-16"
            href={to}
          >
            {toLabel || 'View More'}
          </ClickyLink>
        )}
      </div>
      {children}
    </div>
  )
}

export default GroupHeader
