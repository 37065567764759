import clsx from '@vangst/lib/clsx'
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatSize,
  MdLink,
} from 'react-icons/md'
import { Editor, Element as SlateElement, Transforms } from 'slate'
import { useSlate } from 'slate-react'
import {
  BlockFormat,
  BlockFormatType,
  CustomEditor,
  CustomElement,
  MarkFormat,
  MarkFormatType,
} from './customTypes'
import { insertLink, isLinkActive } from './withLinks'

export function Toolbar() {
  return (
    <div className="flex items-center border-b border-b-grey-light bg-grey-lightest p-2">
      <MarkButton
        title="cmd+h"
        format={MarkFormatType.header}
        icon={MarkFormatType.header}
      />
      <MarkButton
        title="cmd+b"
        format={MarkFormatType.strong}
        icon={MarkFormatType.strong}
      />
      <MarkButton
        title="cmd+i"
        format={MarkFormatType.emphasis}
        icon={MarkFormatType.emphasis}
      />
      <BlockButton
        title="cmd+k"
        format={BlockFormatType.blockquote}
        icon={BlockFormatType.blockquote}
      />
      <BlockButton
        title="cmd+o"
        format={BlockFormatType.orderedList}
        icon={BlockFormatType.orderedList}
      />
      <BlockButton
        title="cmd+l"
        format={BlockFormatType.list}
        icon={BlockFormatType.list}
      />
      <LinkButton />
    </div>
  )
}

export const toggleBlock = (editor: CustomEditor, format: BlockFormat) => {
  const isActive = isBlockActive(editor, format)
  const LIST_TYPES = [BlockFormatType.list, BlockFormatType.orderedList]
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        (!Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          // @ts-ignore
          n.type) as BlockFormatType,
      ),
    split: true,
  })
  const listCheck = isList ? BlockFormatType.listItem : format
  const newProperties: Partial<SlateElement> = {
    // @ts-ignore
    type: isActive ? BlockFormatType.paragraph : listCheck,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block as CustomElement)
  }
}

const isBlockActive = (editor: Editor, format: BlockFormat) => {
  // @ts-ignore --downlevelIteration/generator error
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n as CustomEditor) &&
      SlateElement.isElement(n) &&
      (n as CustomElement).type === (format as BlockFormatType),
  })

  return !!match
}

const isMarkActive = (editor: Editor, format: MarkFormat) => {
  let marks
  try {
    marks = Editor.marks(editor)
  } catch (e) {
    return false
  }
  return marks ? marks[String(format) as keyof typeof marks] === true : false
}

export const toggleMark = (editor: CustomEditor, format: MarkFormat) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, String(format))
  } else {
    Editor.addMark(editor, String(format), true)
  }
}

const MarkButton = ({
  format,
  icon,
  title,
}: {
  format: MarkFormat
  icon: React.ReactNode
  title: string
}) => {
  const editor = useSlate()
  return (
    <button
      title={title}
      type="button"
      className={clsx(
        isMarkActive(editor, format) ? 'text-orange' : 'text-grey',
        'pr-2 transition hocus:text-orange',
      )}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor as CustomEditor, format)
      }}
    >
      {icon === MarkFormatType.header && <MdFormatSize />}
      {icon === MarkFormatType.strong && <MdFormatBold />}
      {icon === MarkFormatType.emphasis && <MdFormatItalic />}
    </button>
  )
}

const BlockButton = ({
  format,
  icon,
  title,
}: {
  format: BlockFormat
  icon: React.ReactNode
  title: string
}) => {
  const editor = useSlate()
  return (
    <button
      className={clsx(
        isBlockActive(editor, format) ? 'text-orange' : 'text-grey',
        'pr-2 transition hocus:text-orange',
      )}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor as CustomEditor, format)
      }}
      title={title}
      type="button"
    >
      {icon === BlockFormatType.blockquote && <MdFormatQuote />}
      {icon === BlockFormatType.orderedList && <MdFormatListNumbered />}
      {icon === BlockFormatType.list && <MdFormatListBulleted />}
    </button>
  )
}

const LinkButton = () => {
  const editor = useSlate()
  return (
    <button
      className={clsx(
        isLinkActive(editor as CustomEditor) ? 'text-orange' : 'text-grey',
        'pr-2 transition hocus:text-orange',
      )}
      onMouseDown={(event) => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor as CustomEditor, url)
      }}
      title="Add Link"
      type="button"
    >
      <MdLink />
    </button>
  )
}
