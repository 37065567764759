import clsx from '@vangst/lib/clsx'
import { memo } from 'react'
import { IconType } from 'react-icons'
import { MdAdd, MdRemove } from 'react-icons/md'

type PropsType = React.HTMLAttributes<HTMLDetailsElement> & {
  readonly as?: 'menuitem' | 'minimal' | 'summary' | 'sidebar'
  readonly at?: 'form' | 'course'
  readonly icon?: IconType
  readonly info?: string
  readonly open?: boolean
  readonly summary?: string | (() => JSX.Element)
}

function Disclosure(props: PropsType) {
  const {
    as = 'summary',
    at,
    children,
    className,
    icon,
    info,
    onClick,
    open,
    summary,
    ...rest
  } = props
  const Icon = icon

  return (
    <details
      className={clsx('group/disclosure', summary !== 'string' && className)}
      open={open}
      {...rest}
    >
      {summary && typeof summary !== 'string' && summary()}
      {summary && typeof summary === 'string' && (
        <summary
          className={clsx(
            as === 'summary' &&
              clsx(
                at === 'course' ? 'mood-blue-dark' : 'mood-orange',
                'flex w-full cursor-pointer list-none items-center justify-between p-4 pr-2 transition',
              ),
            as === 'sidebar' &&
              'mood-white flex w-full cursor-pointer list-none items-center justify-between p-4 pr-2 transition',
            as === 'menuitem' &&
              clsx(
                'mood-white flex w-full cursor-pointer list-none items-center justify-between p-4 pr-2 text-sm uppercase transition',
                'border-b border-grey-light',
                'hocus:bg-orange hocus:text-white',
                'group-open/disclosure:[>_&]:bg-orange group-open/disclosure:[>_&]:font-700 group-open/disclosure:[>_&]:text-white',
              ),
            as === 'minimal' &&
              clsx(
                'flex cursor-pointer list-none items-center justify-between border-b py-4 font-700',
              ),
            className,
          )}
          onClick={onClick}
        >
          {at === 'form' ? (
            <legend>{summary}</legend>
          ) : Icon ? (
            <div className="ml-2 flex py-2 text-md font-700">
              {Icon && <Icon className="mr-2" />}
              <span>{summary}</span>
            </div>
          ) : (
            <span>{summary}</span>
          )}

          {info && <span className="ml-auto mr-4 text-sm">{info}</span>}
          <div className="relative h-6 w-6">
            <MdAdd className="absolute left-0 top-0 transition-opacity duration-200 group-open/disclosure:[>_*_>_*_>_&]:opacity-0" />
            <MdRemove className="absolute left-0 top-0 opacity-0 transition-opacity duration-200 group-open/disclosure:[>_*_>_*_>_&]:opacity-100" />
          </div>
        </summary>
      )}

      {children}
    </details>
  )
}

/**
 * The Disclosure component is a `details > summary + children` combination
 * with custom settings.
 *
 * @example
 * <Disclosure summary="A Disclosure">
 *   <div className="mt-4 flex flex-col">
 *     ...
 *   </div>
 * </Disclosure>
 *
 * <Disclosure as="minimal" summary="A Disclosure as minimal">
 *   <div className="mt-4 flex flex-col">
 *     ...
 *   </div>
 * </Disclosure>
 *
 * <div className="max-w-80 mood-white shadow-lg">
 *   <Disclosure as="menuitem" summary="A Disclosure as menuitem">
 *     <div className="mood-orange flex flex-col">
 *       <MenuBulletClicky>A Link</MenuBulletClicky>
 *       <MenuBulletClicky>A Link</MenuBulletClicky>
 *     </div>
 *   </Disclosure>
 * </div>
 *
 * <form>
 *   <fieldset>
 *     <Disclosure at="form" summary="A default Disclosure as legend">
 *       <div className="mt-4 flex flex-col">
 *         ...
 *       </div>
 *     </Disclosure>
 *   </fieldset>
 * </form>
 *
 * <Disclosure summary={CustomSummary}>
 *   <div className="mt-4 flex flex-col">
 *     ...
 *   </div>
 * </Disclosure>
 */
export default memo(Disclosure)
