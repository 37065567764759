import clsx from '@vangst/lib/clsx'
import useElasticSearch, {
  ElasticSearchEndpoints,
  ElasticSearchOptions,
  ElasticSearchVars,
} from '@vangst/services/oogst/search/useElasticSearch'
import Routes from '@vangst/services/routes'
import Link from 'next/link'
import { Fragment, memo } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { MdArrowDownward, MdOutlineExpandMore, MdSearch } from 'react-icons/md'
import Spinner from '../../components/assets/Spinner'
import AlertText from '../../components/feedback/AlertText'
import EmptyState from '../../components/feedback/EmptyState'
import ClickyExplore from '../../components/navigation/ClickyExplore'
import ClickyLink from '../../components/navigation/ClickyLink'
import Counter from '../../components/views/Counter'
import NodesRenderer from '../nodes/NodesRenderer'
import { AsTypeUnion } from '../nodes/RendererFactory'
import SelectSort from './forms/SelectSort'

type PropsType = React.HTMLAttributes<HTMLDivElement | HTMLDetailsElement> & {
  readonly as: AsTypeUnion
  // TODO: Add an AtTypeUnion
  readonly at:
    | 'infinite'
    | 'preview'
    | 'infinite-preview'
    | 'sidebar'
    | 'options'
    | 'lanes'
  readonly classNameList?: string
  readonly endpoint: ElasticSearchEndpoints
  readonly label?: string
  readonly variables?: ElasticSearchVars
  readonly options?: ElasticSearchOptions
  readonly promos?: boolean
  readonly register?: UseFormRegister<any>
  readonly state?: string
  readonly take?: number
}

function SearchRenderer(props: PropsType) {
  const {
    as,
    at,
    className,
    classNameList,
    label,
    endpoint,
    variables,
    options,
    promos,
    register,
    state,
    take,
    ...rest
  } = props
  const { isFetching, isZero, pagination, subjects } = useElasticSearch(
    endpoint,
    variables,
    options,
  )

  const isBusy = isFetching || subjects == null
  const canTake = take != null && (at === 'preview' || at === 'sidebar')
  const pages = canTake && subjects ? [subjects[0]] : subjects
  const { ref: orderByRef, ...orderByProps } = register
    ? register('orderBy')
    : { ref: null }

  if (at === 'lanes') {
    return (
      <details
        className={clsx('group/dgroup', className)}
        open={isBusy ? false : pagination.totalCount > 0}
        {...rest}
      >
        <summary className="flex cursor-pointer list-none items-center justify-between heading-xs">
          <span className="flow-x-xs items-center">
            <MdOutlineExpandMore className="ml-auto -rotate-90 transition-transform group-open/dgroup:rotate-0" />
            <span>
              {label}: <Counter to={pagination.totalCount} />
            </span>
          </span>
        </summary>
        <div className="my-4 border bg-grey-lightest p-2">
          {isZero && (
            <EmptyState>
              There are currently no applicants in this status.
            </EmptyState>
          )}
          {!isZero &&
            pages?.map((page, i) => {
              const list = canTake ? page?.slice(0, take) : page
              return (
                <Fragment key={`elastic-search-page-${i}`}>
                  {list && <NodesRenderer as={as} subjects={list} />}
                </Fragment>
              )
            })}
        </div>
        {pagination?.nextHref && (
          <div className="flex items-center justify-center">
            <ClickyExplore
              as="icon-text"
              className={clsx('w-full max-w-64', !isFetching && 'motion-south')}
              href={pagination.nextHref}
              icon={isFetching ? Spinner : MdArrowDownward}
              onClick={pagination.nextPage}
            >
              {isBusy ? 'Loading...' : 'Load Next'}
            </ClickyExplore>
          </div>
        )}
      </details>
    )
  }

  return (
    <div className={className} {...rest}>
      {at === 'infinite' && (
        <div className="flex w-full items-center justify-between pb-4">
          <p className="relative">
            <Counter to={pagination?.totalCount || 0} />
            <span className="ml-2">Results</span>
            {isBusy && (
              <Spinner
                className="absolute right-0 top-0"
                width={16}
                height={16}
              />
            )}
          </p>
          {process.env.NEXT_PUBLIC_OOGST_PRODUCTION == null &&
            (endpoint === 'searchJobPostings' ||
              endpoint === 'searchJobPostingsByRecruiters') && (
              <div>
                <SelectSort
                  label="Sort By"
                  className="chevron-white mood-orange max-w-60"
                  name="orderBy"
                  reference={orderByRef}
                  {...orderByProps}
                />
              </div>
            )}
        </div>
      )}

      {at === 'options' && (
        <div className="px-4 pb-4">
          <Counter to={pagination?.totalCount || 0} /> Results
        </div>
      )}

      <div className={clsx(isZero ? 'contain-yc' : classNameList)}>
        {isZero && (
          <ul
            className={clsx(
              'mood-grey-lightest flex items-center justify-center rounded',
              classNameList,
            )}
          >
            <li className="p-16">
              <MdSearch className="mx-auto mb-4 h-8 w-8" />
              {state ? (
                <>
                  <span>No results for {state} - </span>
                  <ClickyLink
                    className="link-orange underline"
                    href={Routes.JOBS}
                  >
                    Search all jobs
                  </ClickyLink>
                </>
              ) : (
                <AlertText className="sm:col-span-2" role="note">
                  No results found.
                </AlertText>
              )}
            </li>
          </ul>
        )}
        {pages?.map((page, i) => {
          const list = canTake ? page?.slice(0, take) : page
          return (
            <Fragment key={`elastic-search-page-${i}`}>
              {list && (
                <NodesRenderer
                  as={as}
                  promote={promos && i === 0}
                  subjects={list}
                />
              )}
              {(at === 'infinite' || at === 'infinite-preview') &&
                pagination?.totalPages &&
                pagination.totalPages > 1 && (
                  <p className="col-span-full my-4 border-b pb-2 text-right text-sm">
                    Page {i + 1} of {pagination.totalPages}
                  </p>
                )}
            </Fragment>
          )
        })}
      </div>
      {(at === 'infinite' || at === 'infinite-preview') &&
        pagination?.nextHref && (
          <div className="flex items-center justify-center">
            <ClickyExplore
              as="icon-text"
              className={clsx('w-full max-w-64', !isFetching && 'motion-south')}
              href={pagination.nextHref}
              icon={isFetching ? Spinner : MdArrowDownward}
              onClick={pagination.nextPage}
            >
              {isBusy ? 'Loading...' : 'Load Next Page'}
            </ClickyExplore>
          </div>
        )}
      {at === 'options' && pagination?.nextHref && (
        <div className="flex items-center justify-center">
          <Link
            className={clsx(
              'flex w-full items-center justify-center gap-4 bg-orange p-4 text-sm text-white transition',
              !isFetching && 'motion-south',
            )}
            href={pagination.nextHref}
            onClick={pagination.nextPage}
          >
            {isFetching ? <Spinner /> : <MdArrowDownward />}
            {isBusy ? 'Loading...' : 'Load Next Page'}
          </Link>
        </div>
      )}
    </div>
  )
}

/**
 * Renders the results of a search query `as` various things `at` various places.
 *
 * @example
 *
 * <SearchRenderer
 *   as="card"
 *   at="preview"
 *   className="mt-4"
 *   classNameList="grid-three-up"
 *   endpoint="searchJobPostings"
 *   variables={{ term: 'vangst', first: 6 }}
 * />
 */
export default memo(SearchRenderer)
