import clsx from '@vangst/lib/clsx'
import { forwardRef } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import AlertText from '../feedback/AlertText'
import Label, { LabelPropsType } from './Label'

type OptionType = {
  readonly label: string
  readonly value: string
}

type PropsType = React.SelectHTMLAttributes<HTMLSelectElement> &
  LabelPropsType & {
    readonly classNameLabel?: string
    readonly error?: string
    readonly hint?: string | null
    readonly name: string
    readonly options: OptionType[]
    readonly reference?: React.Ref<any> | any
  }

/**
 * Turns `options` into an optional type for instances that interact with
 * `Select` on their own.
 */
export type SelectType = Omit<PropsType, 'options'> & { options?: OptionType[] }

/**
 * The `label > text + select + error/hint` elements.
 *
 * @see [MDN select](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select)
 *
 * @example
 * <Select
 *   error="An error occurred."
 *   label="Your Favorite Color"
 *   labelSubtext="(Choose it)"
 *   name="color"
 *   options={[
 *     { label: 'Choose a color', value: '' },
 *     { label: 'Pink', value: 'pink' },
 *     { label: 'Purple', value: 'purple' },
 *   ]}
 *   required
 * />
 * <Select
 *   className="max-w-prose border-dashed border-magenta"
 *   classNameLabel="ml-24"
 *   error="An error occurred."
 *   label="Your Favorite Number"
 *   labelSubtext="(Choose it)"
 *   name="numbers"
 *   options={[
 *     { label: 'Choose a number', value: '' },
 *     { label: 'One', value: 'One' },
 *     { label: 'Two', value: 'Two' },
 *   ]}
 * />
 */
const Select = forwardRef<HTMLInputElement, SelectType>(
  function Select(props, ref) {
    const {
      className,
      classNameLabel,
      error,
      hideLabel,
      hint,
      label,
      labelSubtext,
      name,
      options,
      reference,
      required,
      ...rest
    } = props
    const id = props.id || props.name

    return (
      <label
        className={clsx('flex w-full flex-col gap-2', classNameLabel)}
        htmlFor={id}
      >
        <Label
          hideLabel={hideLabel}
          label={label}
          labelSubtext={labelSubtext}
          required={required}
        />
        <select
          className={clsx(
            'form-select h-12 w-full cursor-pointer py-3 text-sm placeholder:italic focus:ring-orange hocus:border-orange',
            className,
          )}
          id={name}
          name={name}
          ref={ref || reference}
          {...rest}
        >
          {options?.map((option: OptionType) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {(error != null || hint != null) && (
          <span className="ml-auto">
            <AlertText
              className={clsx('text-sm italic', error != null && 'text-red')}
              icon={MdErrorOutline}
              role={error ? 'alert' : 'note'}
            >
              {error || hint}
            </AlertText>
          </span>
        )}
      </label>
    )
  },
)

export default Select
